
















































































































import Vue from 'vue'
import _ from 'lodash'
import {Component, Prop} from 'vue-property-decorator'
import Production from "@/model/Production";
import {Action} from "vuex-class";
import {FETCH_PRODUCTION_BY_ID, FETCH_TANK_LIST} from "@/store/action-types";
import { ProductionPackageResponse, ProductionStepResponse } from '@/types/response'
import moment from "moment";
import TankList from "@/model/Tanklist";

@Component({
  components: {
  }
})
export default class SummaryStep extends Vue {
  @Action(FETCH_PRODUCTION_BY_ID) fetchSummaryData!: { (payload: string): Promise<Production> }
  @Prop({type: String, required: true}) productionId!: string
  @Action(FETCH_TANK_LIST) fetchTankList!: { (): Promise<any[]> }

  private summaryData: ProductionStepResponse[] | null = null
  private comment: string = ''
  private tankList: any[] = []

  get getSummaryStepData (): ProductionStepResponse[] {
    if(this.summaryData) {
      const productSteps: ProductionStepResponse[] = this.getSummaryProductionIngredientStepData
      let otherSteps: ProductionStepResponse[] = this.summaryData.filter((item: ProductionStepResponse) => item.operation !== 'product')
      if(productSteps[0]) {
        otherSteps.push(productSteps[0])
      }
      return _.orderBy(otherSteps, ['order'], ['asc'])
    } else {
      return []
    }
  }

  get getSummaryProductionIngredientStepData (): ProductionStepResponse[] {
    if(this.summaryData) {
      return this.summaryData.filter((item: ProductionStepResponse) => item.operation === 'product')
    } else {
      return []
    }
  }

  getComment (index: number): string {
    let comments: string = ''
    this.getSummaryProductionIngredientStepData.forEach((item: ProductionStepResponse) => {
      comments.length > 0 ? comments += ` | ${item.productionStepIngredient[index].comment}` : comments += item.productionStepIngredient[index].comment
    })
    return comments
  }

  getSpecificPropertieAmount(index: number, specificIndex: number): string {
    let amount: string = ''
    this.getSummaryProductionIngredientStepData.forEach((item: ProductionStepResponse) => {
      amount.length > 0 ? amount += ` | ${item.productionStepIngredient[index].productionStepIngredientSpecificPropertieList[specificIndex].amount}` : amount += item.productionStepIngredient[index].productionStepIngredientSpecificPropertieList[specificIndex].amount
    })
    return amount
  }

  getLabel (item: ProductionStepResponse) {
      return item.label
  }

  getAmount (index: number) {
    let amount: number = 0
    this.getSummaryProductionIngredientStepData.forEach((item: ProductionStepResponse) => {
      amount += item.productionStepIngredient[index].amount
    })
    return amount.toFixed(2)
    /*if(this.summaryData && this.summaryData[1].productionStepIngredient) {
      const foundItem: ProductionStepIngredientResponse | undefined = this.summaryData[1].productionStepIngredient.find((i:ProductionStepIngredientResponse) => i.recipeIngredientId === item.recipeIngredientId)
      return foundItem ? (item.amount + foundItem.amount).toFixed(2) : item.amount.toFixed(2)
    }
    return item.amount.toFixed(2)*/
  }

  getUpdatedDate (item: ProductionStepResponse) {
    if(item.updatedAt) {
      return moment(item.updatedAt,'YYYY-MM-DD').format('D.M.YYYY')
    } else {
      return ''
    }
  }

  getLagerDate (item: ProductionStepResponse) {
    if(item.productionStepLager.createdAt) {
      return moment(item.productionStepLager.createdAt,'YYYY-MM-DD').format('D.M.YYYY')
    } else {
      return ''
    }
  }

  getPackageDate (item: ProductionStepResponse) {
    if(item.productionStepPackage.createdAt) {
      return moment(item.productionStepPackage.createdAt,'YYYY-MM-DD').format('D.M.YYYY')
    } else {
      return ''
    }
  }

  getSummaryVolume(item: ProductionPackageResponse[]): number {
    let volume: number = 0
    item.forEach((i: ProductionPackageResponse) => {
      if(i.amount > 0) {
        volume = volume + (i.volume * i.amount)
      }
    })
    return volume
  }

  getPrimaryDate (item: ProductionStepResponse) {
    if(item.productionStepPrimaryFermentation.createdAt) {
      return moment(item.productionStepPrimaryFermentation.createdAt,'YYYY-MM-DD').format('D.M.YYYY')
    } else {
      return ''
    }
  }

  getTankNumber (item: string): string {
      const foundItem: TankList | undefined = this.tankList.find((i: any) => i.id === item)
    return foundItem ? foundItem.label : ''
  }

  onCommentChange () {
    this.$emit('changeComment', this.comment)
  }

  async fetchTankData():Promise<void> {
    this.tankList = await this.fetchTankList()
  }

  async fetchData (): Promise<void> {
    try {
      const response: Production = await this.fetchSummaryData(this.productionId)
      this.summaryData = response.productionStep!.filter((item: ProductionStepResponse) => item.operation !== 'summary')
    } catch {

    } finally {

    }
  }

  mounted (): void {
    this.fetchData()
    this.fetchTankData()
  }

}
