









import Vue from 'vue'
import {Component} from 'vue-property-decorator'

@Component
export default class ProductionCommentCellRenderer extends Vue {

  get getIcon (): string {
    return "fas fa-info-circle"
  }

  get isIcon (): boolean {
    // @ts-ignore
    if(this.params.data.productionStep[0] && this.params.data.productionStep[0].comment) {
      return true
    }else{
      return false
    }
  }
}
