import Vue from 'vue'
import Router, {Route} from 'vue-router'
import { isUserLoggedInGuard, loginPageGuard, rootPageGuard } from '@/router/guards'
import LoginPage from '@/pages/LoginPage.vue'
import IngredientPage from "@/pages/IngredientPage.vue";
import ProductPage from "@/pages/ProductPage.vue";
import ProductionPage from "@/pages/ProductionPage.vue";
import ProductionStepper from "@/components/ProductionStepper.vue";
import ArchivePage from "@/pages/ArchivePage.vue";
import DashBoardPage from "@/pages/DashboardPage.vue";
import SettingsPage from '@/pages/SettingsPage.vue'

Vue.use(Router)

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,

  routes: [
    {
      path: '/',
      name: 'Root',
      beforeEnter: rootPageGuard
    },
    {
      path: '/login',
      name: 'Login',
      beforeEnter: loginPageGuard,
      component: LoginPage
    },
    {
      path: '/production',
      name: 'Production',
      beforeEnter: isUserLoggedInGuard,
      component: ProductionPage
    },
    {
      path: '/ingredient',
      name: 'Ingredient',
      beforeEnter: isUserLoggedInGuard,
      component: IngredientPage
    },
    {
      path: '/products',
      name: 'Products',
      beforeEnter: isUserLoggedInGuard,
      component: ProductPage
    },

    {
      path: '/production-stepper/:productId',
      name: 'ProductionStepper',
      props: (route: Route) => ({productId: route.params.productId}),
      beforeEnter: isUserLoggedInGuard,
      component: ProductionStepper
    },

    {
      path: '/archive',
      name: 'Archive',
      beforeEnter: isUserLoggedInGuard,
      component: ArchivePage
    },

    {
      path: '/dashboard',
      name: 'Dashboard',
      beforeEnter: isUserLoggedInGuard,
      component: DashBoardPage
    },
    {
      path: '/settings',
      name: 'Settings',
      beforeEnter: isUserLoggedInGuard,
      component: SettingsPage
    },
  ]
})
