import { TankListResponse } from '@/types/response'
import TankType from '@/model/TankType'

export default class TankList {
  private constructor (
        readonly id: string,
        readonly label: string,
        readonly type: TankType
  ) {}

  public static createFromResponse (response: TankListResponse): TankList {
    return new TankList(
      response.id,
      response.label,
      TankType.createFromResponse(response.type)
    )
  }

  public static createFromResponseList (response: TankListResponse[]): TankList[] {
    return response.map((item: TankListResponse) => {
      return new TankList(
          item.id,
          item.label,
          TankType.createFromResponse(item.type)
      )
    })
  }
}
