import axios from 'axios'
import {TOKEN_FIELD} from "@/constants";

function addDefaultHeadersInterceptor (config: any): any {
  config.headers.get['Accept'] = 'application/json'

  const authToken: string | null = localStorage.getItem(TOKEN_FIELD)

  if (authToken) {
    config.headers.get['Authorization'] = `Bearer ${authToken}`
    config.headers.post['Authorization'] = `Bearer ${authToken}`
    config.headers.put['Authorization'] = `Bearer ${authToken}`
    config.headers.delete['Authorization'] = `Bearer ${authToken}`
  }

  return config
}

const config = {
  baseURL: process.env.VUE_APP_API_ROOT
}

const instance = axios.create(config)

instance.interceptors.request.use(addDefaultHeadersInterceptor)

instance.interceptors.response.use(function (response) {
  return response
},function (error) {
  if (error.response.status === 400) {
    error.response.data = {code: 'badRequest'}
    return Promise.reject(error)
  }
  return Promise.reject(error)
})

export default instance
