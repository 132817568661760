





























import Vue from 'vue'
import {Component, Prop} from 'vue-property-decorator'
import AddNewProductDialog from "@/components/AddNewProductDialog.vue";
import {SnackbarStateEnum} from "@/types/enums";
import Product from "@/model/Product";
import {UpdateProductStatusPayload} from "@/types/payload";
import {Action} from "vuex-class";
import {UPDATE_PRODUCT_STATUS} from "@/store/action-types";

@Component({
  components: {
    AddNewProductDialog
  }
})
export default class IngredientProductDetail extends Vue {

  @Prop({ type: Object || null, default: false }) readonly selectedProduct!: Product
  @Action(UPDATE_PRODUCT_STATUS) updateProductStatus!: { (payload: UpdateProductStatusPayload): Promise<Product> }

  private showSnackbar: boolean = false
  private snackBarMessage: string = ''
  private snackbarState: SnackbarStateEnum = SnackbarStateEnum.Success

  get snackbarColor (): boolean {
    return this.snackbarState === SnackbarStateEnum.Success
  }

  showEditDialog (): void {
    this.$emit('showNewProductDialog')
  }

  async onActiveProduct (): Promise<void> {
    const payload:UpdateProductStatusPayload = {
      id: this.selectedProduct.id,
      isArchived: false
    }
    try{
      const response: Product = await this.updateProductStatus(payload)
      this.$emit('activated',response)
    } catch (e) {
    }
  }
}

