






import Vue from 'vue'
import {Component} from 'vue-property-decorator'
import Production from "@/model/Production";

@Component
export default class ProductionStatusCellRenderer extends Vue {

   getIcon (params: Production) {
     // @ts-ignore
    if (this.params.data.completed === true) {
      return 'fas fa-check-circle'
    } else {
      return 'fas fa-exclamation-triangle'
    }
  }

  get iconColor (): string {
    // @ts-ignore
    if (this.params.data.completed === true) {
      return 'green'
    } else {
      return 'error'
    }
  }
}
