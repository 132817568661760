import { YeastNameResponse, YeastTypeResponse } from '@/types/response'

export default class YeastName {
  private constructor (
    readonly id: string,
    readonly label: string,

  ) {}

  public static createFromResponseList (response: YeastNameResponse[]): YeastName[] {
      return response.map((item: YeastNameResponse) => {
          return new YeastName(
              item.id,
              item.label
          )
      })
  }

  public static createFromResponse (response: YeastNameResponse): YeastName {
    return new YeastName(
      response.id,
      response.label
    )
  }
}
