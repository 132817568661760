


















































import Vue from 'vue'
import {Component, Prop, Watch} from 'vue-property-decorator'
import Product from "@/model/Product";
import ProductionIngredientDialog from "@/components/ProductionIngredientDialog.vue";
import {EditedIngredient, ProductionStepIngredientSpecificPropertieList, StepList} from "@/types/types";
import RecipeIngredient from "@/model/RecipeIngredient";
import {Action} from "vuex-class";
import {
  FETCH_PRODUCTION_STEP_BY_ID,
} from "@/store/action-types";
import {ProductionStepIngredientResponse, ProductionStepResponse} from "@/types/response";
import RecipeIngredientSpecificPropertie from '@/model/RecipeIngredientSpecificPropertie'

@Component({
  components: {
    ProductionIngredientDialog
  }
})
export default class ProductionStep extends Vue {
  @Prop({type: Object, required: true}) selectedProduct!: Product
  @Prop({type: Object, required: true}) selectedStep!: StepList
  @Prop({type: Boolean, default: false}) saveIsProgress!: boolean
  @Action(FETCH_PRODUCTION_STEP_BY_ID) fetchProductionStepById!: { (payload: string): Promise<ProductionStepResponse> }

  private showIngredientDialog: boolean = false
  private selectedIngredient: RecipeIngredient | null = null
  private editedIngredientDataList: EditedIngredient[] = []
  private editedIngredientData: EditedIngredient | null = null
  private comment: string = ''
  private isEdit: boolean = false

  @Watch('selectedStep')
  stepItem(): void {
    if(this.selectedStep.productionStepId) {
      this.isEdit = true
      this.selectedIngredient = null
      this.editedIngredientDataList = []
      this.editedIngredientData = null
      this.comment = ''
      this.fetchProductionStepData()
    } else {
      this.isEdit = false
      this.selectedIngredient = null
      this.editedIngredientDataList = []
      this.editedIngredientData = null
      this.comment = ''
    }
  }

  getAmount (value1: any, value2: any): string {
    return value2 ? (parseInt(value1) + parseInt(value2)).toFixed(2) : value1.toFixed(2)
  }


  getSpecificPropertieList (item: RecipeIngredient): RecipeIngredientSpecificPropertie[] {
    return item.recipeIngredientSpecificPropertieList
  }

  getIngredientComment(item: string): string {
    const foundItem: EditedIngredient | undefined = this.editedIngredientDataList.find((i: EditedIngredient) => i.ingredientId === item)
    if(foundItem) {
      return foundItem.comment
    } else {
      return ''
    }
  }

  getEditedSpecificPropertieAmount (specificPropertieId: string, ingredient: RecipeIngredient ): string | null {
    const foundItem: EditedIngredient | undefined = this.editedIngredientDataList.find((i: EditedIngredient) => i.ingredientId === ingredient.id)
    if(foundItem) {
      const foundSpecificPropertie: ProductionStepIngredientSpecificPropertieList | undefined = foundItem.productionStepIngredientSpecificPropertieList.find((item: ProductionStepIngredientSpecificPropertieList) => item.recipeIngredientSpecificPropertieId === specificPropertieId)
      if(foundSpecificPropertie /*&& foundSpecificPropertie.amount > 0*/) {
        return foundSpecificPropertie.amount ? foundSpecificPropertie.amount.toFixed(2) : null
      } else {
        return ''
      }
    } else {
      return ''
    }
  }

  getEditedAmount (item: string): string {
    const foundItem: EditedIngredient | undefined = this.editedIngredientDataList.find((i: EditedIngredient) => i.ingredientId === item)
    if(foundItem) {
      if(foundItem.amount > 0) {
        return '+' + foundItem.amount.toFixed(2)
      } else if(foundItem.amount < 0) {
        return foundItem.amount.toFixed(2)
      } else {
        return ''
      }
    } else {
      return ''
    }
  }

  onCommentChange () {
    this.$emit('changeComment', this.comment)
  }

  onShowIngredientDialog (item: RecipeIngredient): void {
    this.selectedIngredient = item
    const foundItem: EditedIngredient | undefined = this.editedIngredientDataList.find((i: EditedIngredient) => i.ingredientId === item.id)
    if(foundItem) {
      this.editedIngredientData = foundItem
    }
    this.showIngredientDialog = true
  }

  closeProductionIngredientDialog (): void {
    this.editedIngredientData = null
    this.showIngredientDialog = false
  }

  ingredientEdit (payload: EditedIngredient): void {
    const foundItem: EditedIngredient | undefined = this.editedIngredientDataList.find((i: EditedIngredient) => i.ingredientId === payload.ingredientId)
    if(foundItem) {
      foundItem.amount = payload.amount
      foundItem.comment = payload.comment
      foundItem.productionStepIngredientSpecificPropertieList = payload.productionStepIngredientSpecificPropertieList
    } else {
      this.editedIngredientDataList.push(payload)
    }
    this.$emit('editedIngredientData', this.editedIngredientDataList)
    this.editedIngredientData = null
    this.showIngredientDialog = false
  }

  async fetchProductionStepData (): Promise<void> {
    const payload: string = this.selectedStep.productionStepId!
    try {

      const response: ProductionStepResponse = await this.fetchProductionStepById(payload)
      this.comment = response.comment
      this.$emit('changeComment', this.comment)
      response.productionStepIngredient.forEach((item: ProductionStepIngredientResponse) => {
        let amount = item.amount
        const foundItem: RecipeIngredient | undefined = this.selectedProduct.recipe!.recipeIngredient.find((i: RecipeIngredient) => i.id === item.recipeIngredientId)
        if(foundItem) {
          amount = amount - foundItem.amount
        }
        const data: EditedIngredient = {
          ingredientId: item.recipeIngredientId,
          amount: amount,
          comment: item.comment,
          productionStepIngredientSpecificPropertieList: item.productionStepIngredientSpecificPropertieList
        }
        this.editedIngredientDataList.push(data)
      })
      this.$emit('editedIngredientData', this.editedIngredientDataList)
    } catch (e) {
    }
  }

  mounted (): void {
    if(this.selectedStep.productionStepId) {
      this.isEdit = true
      this.fetchProductionStepData()
    } else {
      this.isEdit = false
      this.selectedIngredient = null
      this.editedIngredientDataList = []
      this.editedIngredientData = null
      this.comment = ''
    }
  }

}
