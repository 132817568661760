
























import Vue from 'vue'
import {Component, Prop} from 'vue-property-decorator'
import i18n from "@/plugins/i18n";
import { PackagePayload } from '@/types/payload'
import PackageList from '@/model/PackageList'

@Component
export default class PackageDialog extends Vue {

  $refs!: {
    form: Vue & {
      validate(): boolean
    }
  }

  @Prop({ type: Boolean, default: false }) readonly show!: boolean
  @Prop({ type: Object, default: false }) readonly selectedPackage!: PackageList | null
  @Prop({ type: Boolean, default: false }) readonly createInProgress!: boolean
  @Prop({ type: Boolean, default: false }) readonly updateInProgress!: boolean
  @Prop({ type: Boolean, default: false }) readonly deleteInProgress!: boolean

  private isValid: boolean = false
  private name: string | null = null
  private volume: string | null = null
  private namePackageRules: Array<(v: string) => string | boolean> = [
    (v: string) => !!v || i18n.t('valueIsRequiredMessage').toString(),
  ]
  private volumePackageRules: Array<(v: string) => string | boolean> = [
    (v: string) => !!v || i18n.t('valueIsRequiredMessage').toString(),
  ]

  get getTitle (): string {
    if(this.selectedPackage) {
      return this.$t('settingsPage.updatePackageTitle').toString()
    } else {
      return this.$t('settingsPage.addPackageTitle').toString()
    }
  }

  onCloseDialog (): void {
    if(!this.createInProgress && !this.updateInProgress && !this.deleteInProgress) {
      this.$emit('close')
    }
  }

   onSaveClick (): void {
    if(this.$refs.form.validate() && this.name && this.volume) {
      const payload: PackagePayload = {
        id: this.selectedPackage ? this.selectedPackage.id : '',
        label: this.name,
        volume: this.volume
      }
      if(this.selectedPackage) {
        this.$emit('update',  payload)
      } else {
        this.$emit('create',  payload)
      }
    }
  }

  onDeleteClick (): void {
    if(this.selectedPackage) {
      this.$emit('delete',  this.selectedPackage.id)
    }
  }

  mounted (): void {
    if(this.selectedPackage) {
      this.name = this.selectedPackage.label
      this.volume = this.selectedPackage.volume.toString()
    }
  }
}
