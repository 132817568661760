









import Vue from 'vue'
import {Component} from 'vue-property-decorator'
import moment from "moment";

@Component
export default class ProductionExpirationDateCellRenderer extends Vue {

  get getIcon (): string {
    return 'fas fa-exclamation-triangle'
  }

  get expirationDate (): string {
    // @ts-ignore
    return moment(this.params.data.expirationDate).format('D.M.YYYY')
  }

  get isIcon (): boolean {
    // @ts-ignore
     return !this.params.data.expirationDate
  }
}
