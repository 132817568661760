import { PackageResponse } from '@/types/response'

export default class PackageList {
  private constructor (
    readonly id: string,
    readonly label: string,
    readonly volume: number

  ) {}

  public static createFromResponseList (response: PackageResponse[]): PackageList[] {
      return response.map((item: PackageResponse) => {
          return new PackageList(
              item.id,
              item.label,
              item.volume
          )
      })
  }

    public static createFromResponse (response: PackageResponse): PackageList {
        return new PackageList(
            response.id,
            response.label,
            response.volume
        )
    }
}
