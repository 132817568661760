






























import Vue from 'vue'
import { Component } from 'vue-property-decorator'
import {SnackbarStateEnum} from "@/types/enums";
import {Action} from "vuex-class";
import {FETCH_DASHBOARD_LIST} from "@/store/action-types";
import moment from "moment";
import {DashboardResponse} from "@/types/response";

@Component({
  components: {

  }
})
export default class DashBoardPage extends Vue {

  @Action(FETCH_DASHBOARD_LIST) fetchData!: { (): Promise<DashboardResponse[]> }

  private fetchInProgress: boolean = false
  private showSnackbar: boolean = false
  private snackBarMessage: string = ''
  private snackbarState: SnackbarStateEnum = SnackbarStateEnum.Success
  private headers: any[] = [
    {text: `${this.$t('dashboardPage.nameLabel')}`, align: 'start', sortable: false,value: 'name' },
    {text: `${this.$t('dashboardPage.productionStatusLabel')}`, align: 'start', sortable: false,value: 'lastStepLabel' },
    {text: `${this.$t('dashboardPage.productionNumberLabel')}`, align: 'end', sortable: false,value: 'productionNumber' },
    {text: `${this.$t('dashboardPage.updatedDateLabel')}`, align: 'end', sortable: false,value: 'updatedAt'},
  ]
  private productionList: DashboardResponse[] = []

  get snackbarColor (): boolean {
    return this.snackbarState === SnackbarStateEnum.Success
  }

  getFormattedUpdatedAt (item: string): string {
    return moment(item).format('D.M.YYYY')
  }

  onRowClick (event: MouseEvent, row: any): void {
    this.$router.push({ path: `/production-stepper/${row.item.productId}`, query: {productionId: row.item.id}})
  }

  async fetchProductionList (): Promise<void> {
    this.fetchInProgress = true
    try {
      this.productionList = await this.fetchData()
    } catch (e) {
      this.snackBarMessage = this.$t('ingredientPage.errorData').toString()
      this.snackbarState = SnackbarStateEnum.Error
      this.showSnackbar = true

    } finally {
      this.fetchInProgress = false
    }
  }

  mounted (): void {
    this.fetchProductionList()
  }
}

