




















































































































import Vue from 'vue'
import {Component, Prop, Watch} from 'vue-property-decorator'
import {Action} from "vuex-class";
import { FETCH_PRODUCTION_STEP_BY_ID, FETCH_TANK_LIST, FETCH_YEAST_NAME_LIST, FETCH_YEAST_TYPE } from '@/store/action-types'
import YeastType from "@/model/YeastType";
import i18n from "@/plugins/i18n";
import moment from "moment";
import {ProductionStepPrimaryFermentationPayload} from "@/types/payload";
import {StepList} from "@/types/types";
import {ProductionStepResponse} from "@/types/response";
import TankList from "@/model/Tanklist";
import YeastName from '@/model/YeastName'

@Component({
  components: {
  }
})
export default class FermentationStep extends Vue {

  $refs!: {
    form: Vue & {
      validate(): boolean,
      resetValidation(): boolean
    }
    menuFrom: Vue & {
      save(date: string): void,
    },
  }

  @Prop({type: Object, required: false}) value!: ProductionStepPrimaryFermentationPayload
  @Prop({type: Object, required: true}) selectedStep!: StepList
  @Prop({type: Boolean, default: false}) saveIsProgress!: boolean
  @Action(FETCH_YEAST_TYPE) fetchYeastType!: { (): Promise<YeastType[]> }
  @Action(FETCH_YEAST_NAME_LIST) fetchYeastNameList!: { (): Promise<YeastName[]> }
  @Action(FETCH_PRODUCTION_STEP_BY_ID) fetchProductionStepById!: { (payload: string): Promise<ProductionStepResponse> }
  @Action(FETCH_TANK_LIST) fetchTankList!: { (): Promise<TankList[]> }

  private yeastTypeList: YeastType[]  = []
  private yeastNameList: YeastName[] = []
  private tankList: TankList[] = []
  private isFermentationLoading: boolean = false
  //private usedYeastName: YeastName | null = null

  private generationRules: Array<(v: number) => string | boolean> = [
    (v: number) => {
      const pattern = /^[1-9]*$/
      return pattern.test(v.toString()) || i18n.t('loginPage.valueIsRequiredMessage').toString()
    }
  ]
  private tankNumberRules: Array<(v: string) => string | boolean> = [
    (v: string) => !!v || i18n.t('loginPage.valueIsRequiredMessage').toString(),
  ]
  private tankVolumeRules: Array<(v: string) => string | boolean> = [
    (v: string) => {
      const pattern = /^(?!0*[.,]0*$|[.,]0*$|0*$)\d+[,.]?\d{0,2}$/
      return pattern.test(v) || i18n.t('loginPage.valueIsRequiredMessage').toString()
    }
  ]
  private yeastTypeRules: Array<(v: string) => string | boolean> = [
    (v: string) => !!v || i18n.t('loginPage.valueIsRequiredMessage').toString(),
  ]
  private yeastNameRules: Array<(v: string) => string | boolean> = [
    (v: string) => !!v || i18n.t('loginPage.valueIsRequiredMessage').toString(),
  ]
  private yeastAmountRules: Array<(v: string) => string | boolean> = [
    (v: string) => {
      const pattern = /^(\d+(\.\d+)?)$/
      return pattern.test(v) || i18n.t('loginPage.valueIsRequiredMessage').toString()
    }
  ]
  private dateRules: Array<(v: string) => string | boolean> = [
    (v: string) => !!v || i18n.t('loginPage.valueIsRequiredMessage').toString(),
  ]
  private alcoholPercentageRules: Array<(v: string) => string | boolean> = [
    (v: string) => {
      const pattern = /^(\d+(\.\d+)?)$/
      return pattern.test(v) || i18n.t('loginPage.valueIsRequiredMessage').toString()
    }
  ]
  private residualSugarRules: Array<(v: string) => string | boolean> = [
    (v: string) => {
      const pattern = /^(\d+(\.\d+)?)$/
      return pattern.test(v) || i18n.t('loginPage.valueIsRequiredMessage').toString()
    }
  ]
  private createdAt: string = ''
  private menuFrom: boolean = false
  private isEdit: boolean = false

  @Watch('selectedStep')
  stepItem(): void {
    if(this.selectedStep.productionStepId) {
      this.isEdit = true
      this.fetchProductionStepData()
    } else {
      this.isEdit = false
    }
  }

  get fermentationData(): ProductionStepPrimaryFermentationPayload {
    return this.value
  }

  set fermentationData(val: ProductionStepPrimaryFermentationPayload) {
    this.$emit('input', val)
  }

  get getFormattedDateFrom(): string {
    return moment(this.fermentationData.createdAt, 'YYYY-MM-DD').format('D.M.YYYY')
  }

  get isGenerationDisabled (): boolean {
    const foundItem: YeastType | undefined = this.yeastTypeList.find((i: YeastType) => i.id === this.fermentationData.yeastTypeId)
    if (foundItem && foundItem.isGeneration) {
      return false
    } else {
      return true
    }
  }

  onChangeYeastType (item: string): void {
    const foundItem: YeastType | undefined = this.yeastTypeList.find((i: YeastType) => i.id === item)
    if(foundItem && !foundItem.isGeneration) {
      this.fermentationData.yeastGeneration = 0
    }
  }

  async fetchTankData():Promise<void> {
    this.tankList = await this.fetchTankList()
    this.tankList = this.tankList.filter((item: TankList) => item.type.label === 'Kvasný')
  }

  async fetchData():Promise<void> {
    this.yeastTypeList = await this.fetchYeastType()
    this.yeastNameList = await this.fetchYeastNameList()

   /* if(this.usedYeastName && !this.yeastNameList.find((item: YeastName) => item.id === this.usedYeastName!.id)) {
      this.yeastNameList.unshift(this.usedYeastName)
    }*/
  }

  async fetchProductionStepData (): Promise<void> {
    const payload: string = this.selectedStep.productionStepId!
    try {
      this.isFermentationLoading = true
      const response: ProductionStepResponse = await this.fetchProductionStepById(payload)
      const stepData: ProductionStepPrimaryFermentationPayload = {
        tankId: response.productionStepPrimaryFermentation.tankId,
        tankVolume: response.productionStepPrimaryFermentation.tankVolume.toFixed(2),
        alcoholPercentage: response.productionStepPrimaryFermentation.alcoholPercentage.toFixed(2),
        residualSugar: response.productionStepPrimaryFermentation.residualSugar.toFixed(2),
        yeastNameId: response.productionStepPrimaryFermentation.yeast.yeastName.id,
        yeastTypeId: response.productionStepPrimaryFermentation.yeast.type.id,
        yeastGeneration: response.productionStepPrimaryFermentation.yeast.generation ,
        yeastAmount: response.productionStepPrimaryFermentation.yeast.amount.toFixed(2),
        createdAt: response.productionStepPrimaryFermentation.createdAt
      }

      this.fermentationData = stepData
      //this.usedYeastName = YeastName.createFromResponse(response.productionStepPrimaryFermentation.yeast.name)
    } catch (e) {
    } finally {
      this.isFermentationLoading = false
    }
  }

  public dataValidation(): boolean {
    return this.$refs.form.validate()
  }

  mounted (): void {
    this.fetchTankData()
    this.fetchData()
    if(this.selectedStep.productionStepId) {
      this.isEdit = true
      this.fetchProductionStepData()
    } else {
      this.isEdit = false
    }
  }

}
