import {
    ProductionsResponse, ProductionStepResponse,
} from "@/types/response";
import Product from "@/model/Product";

export default class Production {
  private constructor (
    readonly completed: boolean,
    readonly createdAt: string,
    readonly id: string,
    readonly product: Product,
    readonly productId: string,
    readonly recipeId: string,
    readonly productionNumber: string,
    readonly expirationDate: string,
    readonly productionStep: ProductionStepResponse[] | null
  ) {}

  public static createFromResponse (response: ProductionsResponse): Production {
      return new Production(
          response.completed,
          response.createdAt,
          response.id,
          Product.createFromResponse(response.product, response.recipeId),
          response.productId,
          response.recipeId,
          response.productionNumber,
          response.expirationDate ? response.expirationDate : '',
          response.productionStep,
      )
  }

    public static createFromResponseList (response: ProductionsResponse[]): Production[] {
        return response.map((item: ProductionsResponse) => {
            return new Production(
                item.completed,
                item.createdAt,
                item.id,
                Product.createFromResponse(item.product, item.recipeId),
                item.productId,
                item.recipeId,
                item.productionNumber,
                item.expirationDate ? item.expirationDate : '',
                item.productionStep,
            )
        })
    }

}
