











































import Vue from 'vue'
import { Component } from 'vue-property-decorator'
import AddNewProductDialog from "@/components/AddNewProductDialog.vue";
import {SnackbarStateEnum} from "@/types/enums";
import {Action} from "vuex-class";
import {FETCH_PRODUCT, FETCH_PRODUCTS_LIST} from "@/store/action-types";
import Product from "@/model/Product";
import RecipeIngredient from "@/model/RecipeIngredient";
import IngredientProductDetail from "@/components/IngredientProductDetail.vue";

@Component({
  components: {
    IngredientProductDetail,
    AddNewProductDialog
  }
})
export default class ProductPage extends Vue {

  @Action(FETCH_PRODUCTS_LIST) fetchProductList!: { (payload: boolean): Promise<Product[]> }
  @Action(FETCH_PRODUCT) fetchProduct!: { (payload: string): Promise<Product> }

  private headers: any[]= [
    {text: 'Recept produktu', align: 'start', sortable: false,value: 'name' }
  ]
  private fetchInProgress: boolean = false
  private showSnackbar: boolean = false
  private snackBarMessage: string = ''
  private snackbarState: SnackbarStateEnum = SnackbarStateEnum.Success
  private showAddProductDialog: boolean = false
  private selectedProduct: Product | null = null
  private productList:Product[] = []
  private archivedProducts: boolean = false
  private productLoading: boolean = false

  get snackbarColor (): boolean {
    return this.snackbarState === SnackbarStateEnum.Success
  }

  showEditDialog (): void {
    this.showAddProductDialog = true
  }

  closeAddNewProductDialog (): void {
    this.showAddProductDialog = false
  }

  onRowClick (event: MouseEvent, row: any): void {
    this.selectedProduct = row.item
    this.fetchSelectedProduct()
  }

  onSavedAddProductDialog (item: Product): void {
    this.snackBarMessage = this.$t('productPage.productSaved').toString()
    this.snackbarState = SnackbarStateEnum.Success
    this.showSnackbar = true
    this.showAddProductDialog = false
    this.selectedProduct = item
    this.fetchProductsList()
  }

  onArchivedProduct (): void {
    this.selectedProduct = null
    this.snackBarMessage = this.$t('productPage.productArchived').toString()
    this.snackbarState = SnackbarStateEnum.Success
    this.showSnackbar = true
    this.showAddProductDialog = false
    this.fetchProductsList()
  }

  onActivatedProduct (item: Product): void {
    this.selectedProduct = item
    this.snackBarMessage = this.$t('productPage.productActivated').toString()
    this.snackbarState = SnackbarStateEnum.Success
    this.showSnackbar = true
    this.archivedProducts = false
    this.fetchProductsList()
  }

  onUpdatedProduct (item: Product): void {
    this.snackBarMessage = this.$t('productPage.productUpdated').toString()
    this.snackbarState = SnackbarStateEnum.Success
    this.showSnackbar = true
    this.showAddProductDialog = false
    this.selectedProduct = item
    this.fetchProductsList()
  }

  onArchivedChange (): void {
    this.selectedProduct = null
    this.fetchProductsList()
  }

  async addNewProduct (): Promise<void> {
    this.selectedProduct = Product.createEmpty()
    this.showAddProductDialog = true
  }

  async fetchProductsList (): Promise<void> {
    this.fetchInProgress = true
    const payload: boolean = this.archivedProducts
    try {
      this.productList = await this.fetchProductList(payload)
      if(this.selectedProduct) {
        this.fetchSelectedProduct()
      }
    } catch (e) {
      this.snackBarMessage = this.$t('ingredientPage.errorData').toString()
      this.snackbarState = SnackbarStateEnum.Error
      this.showSnackbar = true
    } finally {
      this.fetchInProgress = false
    }
  }

  async fetchSelectedProduct (): Promise<void> {
    const payload: string = this.selectedProduct!.id
    try {
      this.productLoading = true
      const response: Product = await this.fetchProduct(payload)
      this.selectedProduct = response
      if(this.$vuetify.breakpoint.smAndDown) {
        this.showAddProductDialog = true
      }
    } catch (e) {

    } finally {
      this.productLoading = false
    }
  }

  mounted (): void {
    this.fetchProductsList()
  }
}

