import {UnitResponse} from "@/types/response";

export default class Unit {
  private constructor (
        readonly createdAt: string,
        readonly id: string,
        readonly kind: string,
        readonly symbol: string,
        readonly position: number
  ) {}

  public static createFromResponseList (response: UnitResponse[]): Unit[] {
    return response.map((item: UnitResponse) => {
      return new Unit(
          item.createdAt,
          item.id,
          item.kind,
          item.symbol,
          item.position
      )
    })
  }

  public static createFromResponse (response: UnitResponse): Unit {
      return new Unit(
          response.createdAt,
          response.id,
          response.kind,
          response.symbol,
          response.position
      )
  }
}
