var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{directives:[{name:"resize",rawName:"v-resize",value:(_vm.onResize),expression:"onResize"}],attrs:{"fluid":"","fill-height":""}},[_c('v-snackbar',{attrs:{"color":_vm.snackbarColor ? 'success' : 'error'},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"dark":"","text":"","icon":""},on:{"click":function($event){_vm.showSnackbar = false}}},'v-btn',attrs,false),[_c('v-icon',{on:{"click":function($event){_vm.showSnackbar = false}}},[_vm._v("far fa-times-circle")])],1)]}}]),model:{value:(_vm.showSnackbar),callback:function ($$v) {_vm.showSnackbar=$$v},expression:"showSnackbar"}},[_vm._v(_vm._s(_vm.snackBarMessage))]),(_vm.showProductionDetailDialog)?_c('ProductionDetailDialog',{attrs:{"show":_vm.showProductionDetailDialog,"productionId":_vm.selectedProduction.id,"tankList":_vm.tankList},on:{"close":_vm.closeDetailDialog,"delete":function($event){_vm.showWarningDialog = true},"updatedData":_vm.updatedData}}):_vm._e(),(_vm.showWarningDialog)?_c('DeleteProductionDialog',{attrs:{"show":_vm.showWarningDialog,"warningMessage":_vm.warningProductionMessage},on:{"close":_vm.onCloseWarningDialog,"yes":_vm.onDeleteProduction}}):_vm._e(),_c('div',{staticClass:"page-container"},[_c('v-row',{staticClass:"ma-0"},[_c('v-col',{attrs:{"cols":"3"}},[_c('v-menu',{ref:"menuFrom",attrs:{"close-on-content-click":false,"return-value":_vm.dateFrom,"transition":"scale-transition","offset-y":"","min-width":"auto"},on:{"update:returnValue":function($event){_vm.dateFrom=$event},"update:return-value":function($event){_vm.dateFrom=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":_vm.$t('archivePage.productionDateFromLabel'),"outlined":"","prepend-inner-icon":"fas fa-calendar-alt","readonly":"","hide-details":"","clearable":"","clear-icon":"fal fa-times","dense":"","disabled":_vm.isFilterLoading},on:{"click:prepend-inner":function($event){_vm.menuFrom=true},"click:clear":function($event){_vm.dateFrom = ''}},model:{value:(_vm.formattedDateFrom),callback:function ($$v) {_vm.formattedDateFrom=$$v},expression:"formattedDateFrom"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menuFrom),callback:function ($$v) {_vm.menuFrom=$$v},expression:"menuFrom"}},[_c('v-date-picker',{attrs:{"no-title":"","min":_vm.minAllowedDate,"max":_vm.maxAllowedDate,"first-day-of-week":"1","show-week":"","prev-icon":"fas fa-chevron-left","next-icon":"fas fa-chevron-right"},on:{"change":function($event){return _vm.saveFrom(_vm.dateFrom)}},model:{value:(_vm.dateFrom),callback:function ($$v) {_vm.dateFrom=$$v},expression:"dateFrom"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.menuFrom = false}}},[_vm._v(_vm._s(_vm.$t('archivePage.closeButtonLabel')))])],1)],1)],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-menu',{ref:"menuTo",attrs:{"close-on-content-click":false,"return-value":_vm.dateTo,"transition":"scale-transition","offset-y":"","min-width":"auto"},on:{"update:returnValue":function($event){_vm.dateTo=$event},"update:return-value":function($event){_vm.dateTo=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":_vm.$t('archivePage.productionDateToLabel'),"outlined":"","prepend-inner-icon":"fas fa-calendar-alt","readonly":"","hide-details":"","clearable":"","clear-icon":"fal fa-times","dense":"","disabled":_vm.isFilterLoading},on:{"click:prepend-inner":function($event){_vm.menuTo=true},"click:clear":function($event){_vm.dateTo = ''}},model:{value:(_vm.formattedDateTo),callback:function ($$v) {_vm.formattedDateTo=$$v},expression:"formattedDateTo"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menuTo),callback:function ($$v) {_vm.menuTo=$$v},expression:"menuTo"}},[_c('v-date-picker',{attrs:{"no-title":"","min":_vm.minAllowedDate,"max":_vm.maxAllowedDate,"first-day-of-week":"1","show-week":"","prev-icon":"fas fa-chevron-left","next-icon":"fas fa-chevron-right"},on:{"change":function($event){return _vm.saveTo(_vm.dateTo)}},model:{value:(_vm.dateTo),callback:function ($$v) {_vm.dateTo=$$v},expression:"dateTo"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.menuTo = false}}},[_vm._v(_vm._s(_vm.$t('archivePage.closeButtonLabel')))])],1)],1)],1),_c('v-btn',{staticClass:"pa-0 align-self-center",attrs:{"color":"primary","loading":_vm.isFilterLoading},on:{"click":_vm.onDatesFilterConfirmClick}},[_vm._v("OK")])],1),_c('AgGridVue',{staticClass:"pa-3 pt-0 ag-theme-balham",staticStyle:{"width":"100%","height":"100%","min-height":"500px"},attrs:{"gridOptions":_vm.gridOptions,"columnDefs":_vm.columnDefs,"rowData":_vm.rowData,"frameworkComponents":_vm.gridFrameworkComponents,"overlayNoRowsTemplate":this.$t('archivePage.gridNoRowsMessage')},on:{"row-clicked":_vm.onDetailRowClick,"grid-ready":_vm.onGridReady}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }