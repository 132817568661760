

























































import Vue from 'vue'
import {Component, Prop, Watch} from 'vue-property-decorator'
import moment from "moment";
import {Action} from "vuex-class";
import {FETCH_PACKAGE_LIST, FETCH_PRODUCTION_STEP_BY_ID} from "@/store/action-types";
import PackageList from "@/model/PackageList";
import {ProductionPackageList, ProductionStepPackagePayload} from "@/types/payload";
import i18n from "@/plugins/i18n";
import {
  ProductionPackageResponse,
  ProductionStepResponse
} from "@/types/response";
import {StepList} from "@/types/types";


@Component({
  components: {
  }
})
export default class ConsumerPackingStep extends Vue {

  $refs!: {
    menuFrom: Vue & {
      save(date: string): void,
    },
    form: Vue & {
      validate(): boolean,
      resetValidation(): boolean
    }
  }

  @Prop({type: Object, required: false}) value!: ProductionStepPackagePayload
  @Prop({type: Object, required: true}) selectedStep!: StepList
  @Prop({type: Boolean, default: false}) saveIsProgress!: boolean
  @Action(FETCH_PRODUCTION_STEP_BY_ID) fetchProductionStepById!: { (payload: string): Promise<ProductionStepResponse> }
  @Action(FETCH_PACKAGE_LIST) fetchPackageList!: { (): Promise<PackageList[]> }

  private packageList: PackageList[]  = []
  private createdAt: string = ''
  private menuFrom: boolean = false
  private volumeRules: Array<(v: string) => string | boolean> = [
  (v: string) => {
    const pattern = /^(?!0*[.,]0*$|[.,]0*$|0*$)\d+[,.]?\d{0,2}$/
    return pattern.test(v) || i18n.t('loginPage.valueIsRequiredMessage').toString()
  }
  ]
  private amountRules: Array<(v: string) => string | boolean> = [
    (v: string) => {
      const pattern = /^[0-9]*$/
      return  parseInt(v) >= 0 && pattern.test(v) || i18n.t('loginPage.valueIsRequiredMessage').toString()
    }
  ]
  private isEdit: boolean = false
  private packageListVolume : number | null = null
  private packageLoading: boolean = false


  @Watch('selectedStep')
  stepItem(): void {
    if(this.selectedStep.productionStepId) {
      this.isEdit = true
      this.fetchProductionStepData()
    } else {
      this.isEdit = false
    }
  }

  @Watch('packageListVolume')
  watchPackageListVolume(newVal: any): void {
    if(newVal) {
    }
  }

  get isInputDisabled(): boolean {
    if(this.packageData.volume && parseFloat(this.packageData.volume) > 0) {
      return false
    } else{
      return true
    }
  }

  get getFormattedDateFrom(): string {
    return moment(this.packageData.createdAt, 'YYYY-MM-DD').format('D.M.YYYY')
  }

  get packageData(): ProductionStepPackagePayload {
    return this.value
  }

  set packageData(val: ProductionStepPackagePayload) {
    this.$emit('input', val)
  }

  get isPackageInputError():boolean {
      if(this.packageListVolume! > parseInt(this.packageData.volume) ) {
        return true
      } else {
        return false
      }
  }

  changeInputValue () {
    this.packageListVolume = null
      this.packageData.productionPackageList.forEach((item: any) => {
        this.packageListVolume = this.packageListVolume ? this.packageListVolume + parseInt(item.amount >= 0 ? item.amount : 0) * (item.volume) : parseInt(item.amount >= 0 ? item.amount : 0) * (item.volume)
        this.packageData.volume = this.packageListVolume.toFixed(2)
      })
  }

  async fetchData():Promise<void> {
    try {
      this.packageLoading = true
      this.packageList = await this.fetchPackageList()
      const packageListData: ProductionPackageList[] = this.packageList.map((item: PackageList) => {
        return {
          packageId: item.id,
          amount: 0,
          label: item.label,
          volume: item.volume
        }
      })
      this.packageData.productionPackageList = packageListData
    } catch (e) {

    } finally {
      this.packageLoading = false
    }

  }

  async fetchProductionStepData (): Promise<void> {
    const payload: string = this.selectedStep.productionStepId!
    try {
      const response: ProductionStepResponse = await this.fetchProductionStepById(payload)
      const packageList: ProductionPackageList[] = response.productionStepPackage.productionPackage.map((item: ProductionPackageResponse) => {
        return {
          packageId: item.id,
          amount: item.amount,
          label: item.package ? item.package.label : item.label,
          volume: item.volume
        }
      })
      const stepData: ProductionStepPackagePayload = {
        volume: response.productionStepPackage.volume.toFixed(2),
        createdAt: response.productionStepPackage.createdAt,
        productionPackageList: packageList

      }
      this.packageData = stepData
    } catch (e) {
    }
  }

  public dataValidation(): boolean {
    return this.$refs.form.validate() //&& !this.isPackageInputError
  }

  mounted (): void {
    this.fetchData()
    if(this.selectedStep.productionStepId) {
      this.isEdit = true
      this.fetchProductionStepData()
    } else {
      this.isEdit = false
    }
  }
}
