import { ProductImageResponse } from '@/types/response'

export default class ProductImage {
  private constructor (
    readonly id: string,
    readonly image: string,
    readonly imageType: string
  ) {}

  public static createFromResponse (response: ProductImageResponse): ProductImage {
    return new ProductImage(
      response.id,
      response.image,
      response.imageType
    )
  }
}
