export default class Language {
  private constructor (
    readonly code: string,
    readonly label: string,
    readonly rtl: boolean
  ) {}

  public static createEmpty(): Language {
    return new Language('', '', false)
  }

  public static create(code: string, label: string, rtl: boolean): Language {
    return new Language(code, label, rtl)
  }
}
