import { AuthState } from '@/types/state'
import * as actionTypes from '@/store/action-types'
import * as mutationTypes from '@/store/mutation-types'
import { UserCredentialsPayload } from '@/types/payload'
import CurrentUser from '@/model/CurrentUser'
import * as getterTypes from '@/store/getter-types'
import axios from '@/plugins/axios'
import InvalidCredentialsError from '@/model/error/InvalidCredentialsError'
import { TOKEN_FIELD } from '@/constants'
import {AxiosResponse} from "axios";
import {LoginResponse} from "@/types/response";

const state: AuthState = {
  currentUser: undefined,
  token: undefined
}

const getters = {
  [getterTypes.IS_USER_LOGGED_IN] (state: AuthState): boolean {
    return !!state.currentUser
  },

  [getterTypes.CURRENT_USER] (state: AuthState): CurrentUser | undefined {
    return state.currentUser
  },

  [getterTypes.AUTH_TOKEN] (state: AuthState): string | undefined {
    return state.token
  }
}

const mutations = {
  [mutationTypes.AUTH_SET_CURRENT_USER] (state: AuthState, payload: CurrentUser): void {
    state.currentUser = payload
  },

  [mutationTypes.AUTH_SET_TOKEN] (state: AuthState, payload: string): void {
    state.token = payload
    localStorage.setItem(TOKEN_FIELD, payload)
  },
}

const actions = {
  async [actionTypes.AUTH_INIT_MODULE] ({ commit }: { commit: Function }): Promise<void> {
    const authToken = localStorage.getItem(TOKEN_FIELD)

    if (authToken) {
      commit(mutationTypes.AUTH_SET_TOKEN, authToken)
    }
  },

  async [actionTypes.AUTH_LOG_USER_IN] ({ commit }: { commit: Function }, payload: UserCredentialsPayload): Promise<CurrentUser> {

    try {
      let loginResponse: AxiosResponse = await axios.post('/auth', payload)

      let loginResponseData: LoginResponse = loginResponse.data

      commit(mutationTypes.AUTH_SET_TOKEN, loginResponseData.token)

      let userResponse: AxiosResponse = await axios.get('/user')

      const user: CurrentUser = CurrentUser.createFromResponse(userResponse.data)

      commit(mutationTypes.AUTH_SET_CURRENT_USER, user)

      return user
    } catch (e) {
      throw new InvalidCredentialsError()
    }
  },

  async [actionTypes.AUTH_LOG_USER_IN_WITH_TOKEN] ({ commit }: { commit: Function }): Promise<CurrentUser> {

    let userResponse: AxiosResponse = await axios.get('/user')

    const user: CurrentUser = CurrentUser.createFromResponse(userResponse.data)

    commit(mutationTypes.AUTH_SET_CURRENT_USER, user)

    return user
  },

  async [actionTypes.AUTH_LOG_USER_OUT] ({ commit }: { commit: Function }): Promise<void> {
    commit(mutationTypes.AUTH_SET_CURRENT_USER, undefined)
    localStorage.removeItem(TOKEN_FIELD)
  },

}

const authModule = {
  state,
  getters,
  mutations,
  actions
}

export default authModule
