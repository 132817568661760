//<editor-fold desc="Global actions">
export const TOGGLE_APP_DRAWER = 'TOGGLE_APP_DRAWER'
export const SET_DRAWER_STATE = 'SET_DRAWER_STATE'
export const SWITCH_APPLICATION_LANGUAGE = 'SWITCH_APPLICATION_LANGUAGE'
export const INIT_STORE = 'INIT_STORE'
//</editor-fold>

//<editor-fold desc="Auth module actions">
export const AUTH_INIT_MODULE = 'AUTH_INIT_MODULE'
export const AUTH_LOG_USER_IN = 'AUTH_LOG_USER_IN'
export const AUTH_LOG_USER_OUT = 'AUTH_LOG_USER_OUT'
export const AUTH_LOG_USER_IN_WITH_TOKEN = 'AUTH_LOG_USER_IN_WITH_TOKEN'
//</editor-fold>

export const FETCH_UNIT_LIST = 'FETCH_UNIT_LIST'

export const CREATE_INGREDIENT = 'CREATE_INGREDIENT'
export const FETCH_INGREDIENTS_LIST = 'FETCH_INGREDIENTS_LIST'
export const DELETE_INGREDIENT = 'DELETE_INGREDIENT'
export const UPDATE_INGREDIENT = 'UPDATE_INGREDIENT'

export const FETCH_PRODUCTS_LIST = 'FETCH_PRODUCTS_LIST'
export const FETCH_PRODUCTS_LIST_WITH_IMAGES = 'FETCH_PRODUCTS_LIST_WITH_IMAGES'
export const FETCH_PRODUCT_IMAGE = 'FETCH_PRODUCT_IMAGE'
export const CREATE_PRODUCT = 'CREATE_PRODUCT'
export const FETCH_PRODUCT = 'FETCH_PRODUCT'
export const UPDATE_PRODUCT = 'UPDATE_PRODUCT'
export const UPDATE_PRODUCT_STATUS = 'UPDATE_PRODUCT_STATUS'

export const CREATE_PRODUCTION = "CREATE_PRODUCTION"
export const CREATE_PRODUCTION_STEP = "CREATE_PRODUCTION_STEP"
export const FETCH_PRODUCTION_BY_ID = "FETCH_PRODUCTION_BY_ID"
export const UPDATE_PRODUCTION_STEP = "UPDATE_PRODUCTION_STEP"
export const FETCH_PRODUCTION_STEP_BY_ID = "FETCH_PRODUCTION_STEP_BY_ID"
export const FETCH_ARCHIVE_LIST = "FETCH_ARCHIVE_LIST"
export const DELETE_PRODUCTION_BY_ID = "DELETE_PRODUCTION_BY_ID"
export const UPDATE_PRODUCTION = "UPDATE_PRODUCTION"
export const FETCH_DASHBOARD_LIST = 'FETCH_DASHBOARD_LIST'
export const FETCH_YEAST_TYPE = 'FETCH_YEAST_TYPE'
export const FETCH_YEAST_NAME_LIST = 'FETCH_YEAST_NAME_LIST'
export const CREATE_YEAST_NAME = 'CREATE_YEAST_NAME'
export const UPDATE_YEAST_NAME = 'UPDATE_YEAST_NAME'
export const DELETE_YEAST_NAME = 'DELETE_YEAST_NAME'
export const FETCH_PACKAGE_LIST = 'FETCH_PACKAGE_LIST'
export const CREATE_PACKAGE = 'CREATE_PACKAGE'
export const UPDATE_PACKAGE = 'UPDATE_PACKAGE'
export const DELETE_PACKAGE = 'DELETE_PACKAGE'
export const FETCH_TANK_TYPE_LIST = 'FETCH_TANK_TYPE_LIST'
export const FETCH_TANK_LIST = 'FETCH_TANK_LIST'
export const CREATE_TANK = 'CREATE_TANK'
export const UPDATE_TANK = 'UPDATE_TANK'
export const DELETE_TANK = 'DELETE_TANK'
export const UPDATE_PRODUCT_IMAGE = 'UPDATE_PRODUCT_IMAGE'