import { TankTypeResponse } from '@/types/response'

export default class TankType {
  private constructor (
        readonly id: string,
        readonly label: string,
  ) {}

  public static createFromResponse (response: TankTypeResponse): TankType {
      return new TankType(
        response.id,
        response.label
      )
  }

  public static createFromResponseList (response: TankTypeResponse[]): TankType[] {
    return response.map((item: TankTypeResponse) => {
      return new TankType(
        item.id,
        item.label
      )
    })
  }
}
