import {UserResponse} from "@/types/response";

export default class CurrentUser {
  private constructor (
    readonly firstName: string,
    readonly familyName: string
  ) {}

  get fullName (): string {
    return `${this.firstName} ${this.familyName}`
  }

  /*public static createEmpty(): CurrentUser {
    return new CurrentUser(0, '', '')
  }*/

  public static createFromResponse (response: UserResponse): CurrentUser {
    return new CurrentUser(
        response.firstName,
        response.familyName
    )
  }
}
