











































import Vue from 'vue'
import { Component } from 'vue-property-decorator'
import AddNewIngredientDialog from "@/components/AddNewIngredientDialog.vue";
import {Action} from "vuex-class";
import {DELETE_INGREDIENT, FETCH_INGREDIENTS_LIST} from "@/store/action-types";
import Ingredient from "@/model/Ingredient";
import EditIngredientDialog from "@/components/EditIngredientDialog.vue";
import { SnackbarStateEnum } from "@/types/enums";
import WarningDialog from "@/components/WarningDialog.vue";

@Component({
  components: {
    AddNewIngredientDialog,
    EditIngredientDialog,
    WarningDialog
  }
})

export default class IngredientPage extends Vue {

  @Action(FETCH_INGREDIENTS_LIST) fetchIngredientList!: { (): Promise<Ingredient[]> }
  @Action(DELETE_INGREDIENT) deleteIngredient!: { (payload: string): Promise<void> }

  private snackbarState: SnackbarStateEnum = SnackbarStateEnum.Success
  private snackBarMessage: string = ''
  private showSnackbar: boolean = false
  private headers: any[] = [
    {text: `${this.$t('appMenu.ingredients')}`, align: 'start', sortable: false,value: 'name' },
    {text: `${this.$t('ingredientPage.unit')}`, align: 'end', sortable: false,value: 'unit.symbol'},
  ]
  private ingredientList: Ingredient[] = []
  private selectedIngredient: Ingredient | null = null
  private showAddIngredientDialog: boolean = false
  private showEditIngredientDialog: boolean = false
  private fetchInProgress: boolean = false
  private showWarningDialog: boolean = false
  private warningMessage: string =  `${this.$t('ingredientPage.warningMessage')}`
  private deleteIngredientLoading: boolean = false

  get snackbarColor (): boolean {
    return this.snackbarState === SnackbarStateEnum.Success
  }

  onAddNewIngredient (): void {
    this.showAddIngredientDialog = true
  }

  onCloseAddIngredientDialog (): void {
    this.showAddIngredientDialog = false
  }

  onRowClick (event: MouseEvent, row: any): void {
    this.selectedIngredient = row.item
    this.showEditIngredientDialog = true
  }

  onCloseEditIngredientDialog (): void {
    this.showEditIngredientDialog = false
  }

  onSavedAddIngredientDialog (): void {
    this.snackBarMessage = this.$t('ingredientPage.ingredientSaved').toString()
    this.snackbarState = SnackbarStateEnum.Success
    this.showSnackbar = true
    this.showAddIngredientDialog = false
    this.fetchIngredientsList()
  }

  onSavedEditIngredientDialog (): void {
    this.snackBarMessage = this.$t('ingredientPage.ingredientEdit').toString()
    this.snackbarState = SnackbarStateEnum.Success
    this.showSnackbar = true
    this.showEditIngredientDialog = false
    this.fetchIngredientsList()
  }

  onCloseWarningDialog (): void {
    this.showWarningDialog = false
  }

  async fetchIngredientsList (): Promise<void> {
    this.fetchInProgress = true
    try {
      this.ingredientList = await this.fetchIngredientList()
    } catch (e) {
      this.snackBarMessage = this.$t('ingredientPage.errorData').toString()
      this.snackbarState = SnackbarStateEnum.Error
      this.showSnackbar = true

    } finally {
      this.fetchInProgress = false
    }
  }

  async onDeleteIngredient (): Promise<void> {
    this.showWarningDialog = false

    if(this.selectedIngredient && this.selectedIngredient.id) {
      let payload: string = this.selectedIngredient.id

      try {
        this.deleteIngredientLoading = true
        await this.deleteIngredient(payload)
        this.showEditIngredientDialog = false
        this.snackBarMessage = this.$t('ingredientPage.ingredientDelete').toString()
        this.snackbarState = SnackbarStateEnum.Success
        this.showSnackbar = true
        this.fetchIngredientsList()
      } catch (e) {
        this.snackBarMessage = this.$t(`ingredientPage.${e.response.data.code}`).toString()
        this.snackbarState = SnackbarStateEnum.Error
        this.showSnackbar = true
      } finally {
        this.deleteIngredientLoading = false
      }
    }
  }

  mounted (): void {
    this.fetchIngredientsList()
  }
}
