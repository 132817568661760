





























import Vue from 'vue'
import { Component } from 'vue-property-decorator'
import { APP_DRAWER_STATE } from '@/store/getter-types'
import { SET_DRAWER_STATE } from '@/store/action-types'
import { Action } from 'vuex-class'

@Component
export default class AppDrawer extends Vue {
  @Action(SET_DRAWER_STATE) setDrawerState!: { (payload: boolean): void }

  get showAppDrawer (): boolean {
    return this.$store.getters[APP_DRAWER_STATE]
  }

  set showAppDrawer (newVal: boolean) {
    this.setDrawerState(newVal)
  }
}
