






















import Vue from 'vue'
import {Component, Prop} from 'vue-property-decorator'
import i18n from "@/plugins/i18n";
import YeastName from '@/model/YeastName'
import { YeastNamePayload } from '@/types/payload'

@Component
export default class YeastDialog extends Vue {

  $refs!: {
    form: Vue & {
      validate(): boolean
    }
  }

  @Prop({ type: Boolean, default: false }) readonly show!: boolean
  @Prop({ type: Object, default: false }) readonly selectedYeast!: YeastName | null
  @Prop({ type: Boolean, default: false }) readonly createInProgress!: boolean
  @Prop({ type: Boolean, default: false }) readonly updateInProgress!: boolean
  @Prop({ type: Boolean, default: false }) readonly deleteInProgress!: boolean

  private isValid: boolean = false
  private name: string | null = null
  private nameYeastRules: Array<(v: string) => string | boolean> = [
    (v: string) => !!v || i18n.t('loginPage.valueIsRequiredMessage').toString(),
  ]

  get getTitle (): string {
    if(this.selectedYeast) {
      return this.$t('settingsPage.updateYeastNameTitle').toString()
    } else {
      return this.$t('settingsPage.addYeastNameTitle').toString()
    }
  }

  onCloseDialog (): void {
    if(!this.createInProgress && !this.updateInProgress && !this.deleteInProgress) {
      this.$emit('close')
    }
  }

   onSaveClick (): void {
    if(this.$refs.form.validate() && this.name) {
      const payload: YeastNamePayload = {
        id: this.selectedYeast ? this.selectedYeast.id : '',
        label: this.name
      }
      if(this.selectedYeast) {
        this.$emit('update',  payload)
      } else {
        this.$emit('create',  payload)
      }
    }
  }

  onDeleteClick (): void {
    if(this.selectedYeast) {
      this.$emit('delete',  this.selectedYeast.id)
    }
  }

  mounted (): void {
    if(this.selectedYeast) {
      this.name = this.selectedYeast.label
    }
  }
}
