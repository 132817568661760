







































































































import Vue from 'vue'
import { Component } from 'vue-property-decorator'
import {SnackbarStateEnum} from "@/types/enums";
import {Action} from "vuex-class";
import {
  CREATE_PACKAGE, CREATE_TANK,
  CREATE_YEAST_NAME, DELETE_PACKAGE, DELETE_TANK,
  DELETE_YEAST_NAME, FETCH_PACKAGE_LIST, FETCH_TANK_LIST, FETCH_TANK_TYPE_LIST,
  FETCH_YEAST_NAME_LIST, UPDATE_PACKAGE, UPDATE_TANK,
  UPDATE_YEAST_NAME
} from '@/store/action-types'
import YeastName from '@/model/YeastName'
import YeastDialog from '@/components/settings/YeastDialog.vue'
import { PackagePayload, TankPayload, YeastNamePayload } from '@/types/payload'
import PackageDialog from '@/components/settings/PackageDialog.vue'
import PackageList from '@/model/PackageList'
import TankList from '@/model/Tanklist'
import TankDialog from '@/components/settings/TankDialog.vue'
import TankType from '@/model/TankType'

@Component({
  components: {
    YeastDialog,
    PackageDialog,
    TankDialog
  }
})
export default class SettingsPage extends Vue {

  @Action(FETCH_YEAST_NAME_LIST) fetchYeastNameList!: { (): Promise<YeastName[]> }
  @Action(CREATE_YEAST_NAME) createYeastName!: { (payload: YeastNamePayload): Promise<YeastName> }
  @Action(UPDATE_YEAST_NAME) updateYeastName!: { (payload: YeastNamePayload): Promise<YeastName> }
  @Action(DELETE_YEAST_NAME) deleteYeastName!: { (payload: string): Promise<void> }

  @Action(FETCH_PACKAGE_LIST) fetchPackage!: { (): Promise<PackageList[]> }
  @Action(CREATE_PACKAGE) createPackage!: { (payload: PackagePayload): Promise<PackageList> }
  @Action(UPDATE_PACKAGE) updatePackage!: { (payload: PackagePayload): Promise<PackageList> }
  @Action(DELETE_PACKAGE) deletePackage!: { (payload: string): Promise<void> }

  @Action(FETCH_TANK_TYPE_LIST) fetchTankType!: { (): Promise<TankType[]> }
  @Action(FETCH_TANK_LIST) fetchTank!: { (): Promise<TankList[]> }
  @Action(CREATE_TANK) createTank!: { (payload: TankPayload): Promise<TankList> }
  @Action(UPDATE_TANK) updateTank!: { (payload: TankPayload): Promise<TankList> }
  @Action(DELETE_TANK) deleteTank!: { (payload: string): Promise<void> }

  private fetchYeastInProgress: boolean = false
  private createYeastInProgress: boolean = false
  private updateYeastInProgress: boolean = false
  private deleteYeastInProgress: boolean = false
  private fetchPackageInProgress: boolean = false
  private createPackageInProgress: boolean = false
  private updatePackageInProgress: boolean = false
  private deletePackageInProgress: boolean = false
  private fetchTankInProgress: boolean = false
  private fetchTankTypeInProgress: boolean = false
  private createTankInProgress: boolean = false
  private updateTankInProgress: boolean = false
  private deleteTankInProgress: boolean = false
  private showSnackbar: boolean = false
  private snackBarMessage: string = ''
  private snackbarState: SnackbarStateEnum = SnackbarStateEnum.Success
  private yeastHeaders: any[] = [
    {text: this.$t('settingsPage.yeast'), align: 'start', sortable: false, value: 'label' },
  ]
  private yeastList: YeastName[] = []
  private selectedYeast: YeastName | null = null
  private showYeastDialog: boolean = false
  private packageHeaders: any[] = [
    {text: this.$t('settingsPage.packageName'), align: 'start', sortable: false, value: 'label' },
    {text: this.$t('settingsPage.packageVolume'), align: 'end', sortable: false, value: 'volume' }
  ]
  private packageList: PackageList[] = []
  private selectedPackage: PackageList | null = null
  private showPackageDialog: boolean = false
  private tankHeaders: any[] = [
    {text: this.$t('settingsPage.tankName'), align: 'start', sortable: false, value: 'label' },
    {text: this.$t('settingsPage.tankType'), align: 'end', sortable: false, value: 'type.label' }
  ]
  private tankTypeList: TankType[] = []
  private tankList: TankList[] = []
  private selectedTank: TankList | null = null
  private showTankDialog: boolean = false

  get snackbarColor (): boolean {
    return this.snackbarState === SnackbarStateEnum.Success
  }

  onYeastRowClick (event: MouseEvent, row: any): void {
    this.selectedYeast = row.item
    this.showYeastDialog = true
  }

  addNewYeastName (): void {
    this.selectedYeast = null
    this.showYeastDialog = true
  }

  closeYeastDialog (): void {
    this.showYeastDialog = false
  }

  async createYeast (item: YeastNamePayload): Promise<void> {
    this.createYeastInProgress = true
    try {
      await this.createYeastName(item)
      this.fetchYeastList()

      this.showYeastDialog = false
      this.snackBarMessage = this.$t('settingsPage.createSuccess').toString()
      this.snackbarState = SnackbarStateEnum.Success
      this.showSnackbar = true
    } catch (e) {
      this.snackBarMessage = this.$t(`settingsPage.${e.response.data.code}`).toString()
      this.snackbarState = SnackbarStateEnum.Error
      this.showSnackbar = true
    } finally {
      this.createYeastInProgress = false
    }
  }

  async updateYeast (item: YeastNamePayload): Promise<void> {
    this.updateYeastInProgress = true
    try {
      await this.updateYeastName(item)
      this.fetchYeastList()

      this.showYeastDialog = false
      this.snackBarMessage = this.$t('settingsPage.updateSuccess').toString()
      this.snackbarState = SnackbarStateEnum.Success
      this.showSnackbar = true
    } catch (e) {
      this.snackBarMessage = this.$t(`settingsPage.${e.response.data.code}`).toString()
      this.snackbarState = SnackbarStateEnum.Error
      this.showSnackbar = true
    } finally {
      this.updateYeastInProgress = false
    }
  }

  async deleteYeast (item: string): Promise<void> {
    this.deleteYeastInProgress = true
    try {
      await this.deleteYeastName(item)
      this.fetchYeastList()

      this.showYeastDialog = false
      this.snackBarMessage = this.$t('settingsPage.deleteSuccess').toString()
      this.snackbarState = SnackbarStateEnum.Success
      this.showSnackbar = true
    } catch (e) {
      this.snackBarMessage = this.$t(`settingsPage.${e.response.data.code}`).toString()
      this.snackbarState = SnackbarStateEnum.Error
      this.showSnackbar = true
    } finally {
      this.deleteYeastInProgress = false
    }
  }

  onPackageRowClick (event: MouseEvent, row: any): void {
    this.selectedPackage = row.item
    this.showPackageDialog = true
  }

  addNewPackage (): void {
    this.selectedPackage = null
    this.showPackageDialog = true
  }

  closePackageDialog (): void {
    this.showPackageDialog = false
  }

  async onCreatePackage (item: PackagePayload): Promise<void> {
    this.createPackageInProgress = true
    try {
      await this.createPackage(item)
      this.fetchPackageList()

      this.showPackageDialog = false
      this.snackBarMessage = this.$t('settingsPage.createSuccess').toString()
      this.snackbarState = SnackbarStateEnum.Success
      this.showSnackbar = true
    } catch (e) {
      this.snackBarMessage = this.$t(`settingsPage.${e.response.data.code}`).toString()
      this.snackbarState = SnackbarStateEnum.Error
      this.showSnackbar = true
    } finally {
      this.createPackageInProgress = false
    }
  }

  async onUpdatePackage (item: PackagePayload): Promise<void> {
    this.updatePackageInProgress = true
    try {
      await this.updatePackage(item)
      this.fetchPackageList()

      this.showPackageDialog = false
      this.snackBarMessage = this.$t('settingsPage.updateSuccess').toString()
      this.snackbarState = SnackbarStateEnum.Success
      this.showSnackbar = true
    } catch (e) {
      this.snackBarMessage = this.$t(`settingsPage.${e.response.data.code}`).toString()
      this.snackbarState = SnackbarStateEnum.Error
      this.showSnackbar = true
    } finally {
      this.updatePackageInProgress = false
    }
  }

  async onDeletePackage (item: string): Promise<void> {
    this.deletePackageInProgress = true
    try {
      await this.deletePackage(item)
      this.fetchPackageList()

      this.showPackageDialog = false
      this.snackBarMessage = this.$t('settingsPage.deleteSuccess').toString()
      this.snackbarState = SnackbarStateEnum.Success
      this.showSnackbar = true
    } catch (e) {
      this.snackBarMessage = this.$t(`settingsPage.${e.response.data.code}`).toString()
      this.snackbarState = SnackbarStateEnum.Error
      this.showSnackbar = true
    } finally {
      this.deletePackageInProgress = false
    }
  }


  onTankRowClick (event: MouseEvent, row: any): void {
    this.selectedTank = row.item
    this.showTankDialog = true
  }

  addNewTank (): void {
    this.selectedTank = null
    this.showTankDialog = true
  }

  closeTankDialog (): void {
    this.showTankDialog = false
  }

  async onCreateTank (item: TankPayload): Promise<void> {
    this.createTankInProgress = true
    try {
      await this.createTank(item)
      this.fetchTankList()
      this.showTankDialog = false
      this.snackBarMessage = this.$t('settingsPage.createSuccess').toString()
      this.snackbarState = SnackbarStateEnum.Success
      this.showSnackbar = true
    } catch (e) {
      this.snackBarMessage = this.$t(`settingsPage.${e.response.data.code}`).toString()
      this.snackbarState = SnackbarStateEnum.Error
      this.showSnackbar = true
    } finally {
      this.createTankInProgress = false
    }
  }

  async onUpdateTank (item: TankPayload): Promise<void> {
    this.updateTankInProgress = true
    try {
      await this.updateTank(item)
      this.fetchTankList()

      this.showTankDialog = false
      this.snackBarMessage = this.$t('settingsPage.updateSuccess').toString()
      this.snackbarState = SnackbarStateEnum.Success
      this.showSnackbar = true
    } catch (e) {
      this.snackBarMessage = this.$t(`settingsPage.${e.response.data.code}`).toString()
      this.snackbarState = SnackbarStateEnum.Error
      this.showSnackbar = true
    } finally {
      this.updateTankInProgress = false
    }
  }

  async onDeleteTank (item: string): Promise<void> {
    this.deleteTankInProgress = true
    try {
      await this.deleteTank(item)
      this.fetchTankList()

      this.showTankDialog = false
      this.snackBarMessage = this.$t('settingsPage.deleteSuccess').toString()
      this.snackbarState = SnackbarStateEnum.Success
      this.showSnackbar = true
    } catch (e) {
      this.snackBarMessage = this.$t(`settingsPage.${e.response.data.code}`).toString()
      this.snackbarState = SnackbarStateEnum.Error
      this.showSnackbar = true
    } finally {
      this.deleteTankInProgress = false
    }
  }

  async fetchYeastList (): Promise<void> {
    this.fetchYeastInProgress = true
    try {
      this.yeastList = await this.fetchYeastNameList()
    } catch (e) {
      this.snackBarMessage = this.$t('settingsPage.fetchError').toString()
      this.snackbarState = SnackbarStateEnum.Error
      this.showSnackbar = true

    } finally {
      this.fetchYeastInProgress = false
    }
  }

  async fetchPackageList (): Promise<void> {
    this.fetchPackageInProgress = true
    try {
      this.packageList = await this.fetchPackage()
    } catch (e) {
      this.snackBarMessage = this.$t('settingsPage.fetchError').toString()
      this.snackbarState = SnackbarStateEnum.Error
      this.showSnackbar = true

    } finally {
      this.fetchPackageInProgress = false
    }
  }

  async fetchTankList (): Promise<void> {
    this.fetchTankInProgress = true
    try {
      this.tankList = await this.fetchTank()
    } catch (e) {
      this.snackBarMessage = this.$t('settingsPage.fetchError').toString()
      this.snackbarState = SnackbarStateEnum.Error
      this.showSnackbar = true

    } finally {
      this.fetchTankInProgress = false
    }
  }

  async fetchTankTypeList (): Promise<void> {
    this.fetchTankTypeInProgress = true
    try {
      this.tankTypeList = await this.fetchTankType()
    } catch (e) {
      this.snackBarMessage = this.$t('settingsPage.fetchError').toString()
      this.snackbarState = SnackbarStateEnum.Error
      this.showSnackbar = true

    } finally {
      this.fetchTankTypeInProgress = false
    }
  }

  mounted (): void {
    this.fetchYeastList()
    this.fetchPackageList()
    this.fetchTankList()
    this.fetchTankTypeList()
  }
}

