

























































































import Vue from 'vue'
import {Component, Prop, Watch} from 'vue-property-decorator'
import ProductionStep from "@/components/ProductionStep.vue";
import Product from "@/model/Product";
import {Action} from "vuex-class";
import {
  CREATE_PRODUCTION,
  CREATE_PRODUCTION_STEP,
  FETCH_PRODUCT,
  FETCH_PRODUCTION_BY_ID, UPDATE_PRODUCTION,
  UPDATE_PRODUCTION_STEP
} from '@/store/action-types'
import ProductConfiguration from "@/model/ProductConfiguration";
import {
  CreateProductionStepPayload,
  ProductionStepIngredientListPayload,
  ProductionStepLagerPayload, ProductionStepPackagePayload,
  ProductionStepPrimaryFermentationPayload, updateProductionPayload,
  UpdateProductionStepPayload,
} from '@/types/payload'
import {EditedIngredient, ProductionStepIngredientSpecificPropertieList, StepList} from "@/types/types";
import RecipeIngredient from "@/model/RecipeIngredient";
import SummaryStep from "@/components/SummaryStep.vue";
import Production from "@/model/Production";
import {SnackbarStateEnum} from "@/types/enums";
import moment from "moment";
import {ProductionStepResponse} from "@/types/response";
import FermentationStep from "@/components/FermentationStep.vue";
import LagerStep from "@/components/LagerStep.vue";
import ConsumerPackingStep from "@/components/ConsumerPackingStep.vue";
import RecipeIngredientSpecificPropertie from '@/model/RecipeIngredientSpecificPropertie'
import UpdateProductionNumberDialog from '@/components/UpdateProductionNumberDialog.vue'

@Component({
  components: {
    ProductionStep,
    SummaryStep,
    FermentationStep,
    LagerStep,
    ConsumerPackingStep,
    UpdateProductionNumberDialog
  }
})
export default class ProductionStepper extends Vue {
  @Prop({type: String, required: true}) productId!: string
  @Action(FETCH_PRODUCT) fetchProduct!: { (payload: string): Promise<Product> }
  @Action(CREATE_PRODUCTION) createProduction!: { (payload: string): Promise<string> }
  @Action(CREATE_PRODUCTION_STEP) createProductionStep!: { (payload: CreateProductionStepPayload): Promise<Production> }
  @Action(UPDATE_PRODUCTION_STEP) updateProductionStep!: { (payload: UpdateProductionStepPayload): Promise<Production> }
  @Action(FETCH_PRODUCTION_BY_ID) fetchProduction!: { (payload: string): Promise<Production> }
  @Action(UPDATE_PRODUCTION) updateProduction!: { (payload: updateProductionPayload): Promise<Production> }

  $refs!: {
    title: HTMLElement
    cardTitle: HTMLElement
    footerRow: HTMLElement
    tabs: HTMLElement
    fermentationComponent: Vue & {
      dataValidation(): boolean
    }
    lagerComponent: Vue & {
      dataValidation(): boolean
    }
    packageComponent: Vue & {
      dataValidation(): boolean
    }
  }

  private tabs: number = 0
  private selectedProduct: Product | null = null
  private selectedStep: StepList | null = null
  private productionId: string | null = null
  private editedIngredientDataList: EditedIngredient[] = []
  private comment: string = ''
  private stepList: StepList[] = []
  private maxEnabledStep: number = 0
  private showSnackbar: boolean = false
  private snackBarMessage: string = ''
  private snackbarState: SnackbarStateEnum = SnackbarStateEnum.Success
  private saveIsProgress: boolean = false
  private saveNextIsProgress: boolean = false
  private isSaveInProgress: boolean = false
  private selectedProduction: Production | null = null
  private cardHeight: string = 'height: 200px'
  private fermentationData: ProductionStepPrimaryFermentationPayload = {
    tankId: '' ,
    tankVolume: '0',
    alcoholPercentage: '0',
    residualSugar: '0',
    yeastNameId: '',
    yeastTypeId: '',
    yeastGeneration: 0,
    yeastAmount: '0',
    createdAt: moment().format('YYYY-MM-DD')
  }
  private lagerData: ProductionStepLagerPayload = {
    tankId: '',
    tankVolume: '0',
    alcoholPercentage: '0',
    residualSugar: '0',
    createdAt: moment().format('YYYY-MM-DD')
  }
  private packageData: ProductionStepPackagePayload = {
    volume: '0',
    createdAt: moment().format('YYYY-MM-DD'),
    productionPackageList: []
  }
  private productionNumber: string = ''
  private loading: boolean = true
  private showUpdateProductionNumberDialog: boolean = false
  private loadingProductionNumber: boolean = false

  @Watch('tabs')
  tabsItem(): void {
    this.selectedStep = this.stepList.length > 0 ? this.stepList[this.tabs] : null
  }

  get snackbarColor (): boolean {
    return this.snackbarState === SnackbarStateEnum.Success
  }

  get isProductionStepFermentationValid (): boolean {
     return this.selectedStep!.operation !== 'primary' || (this.selectedStep!.operation === 'primary' && this.$refs.fermentationComponent.dataValidation());
  }

  get isProductionStepLagerValid (): boolean {
    return this.selectedStep!.operation !== 'lager' || (this.selectedStep!.operation === 'lager' && this.$refs.lagerComponent.dataValidation());
  }

  get isProductionStepPackageValid (): boolean {
    return this.selectedStep!.operation !== 'package' || (this.selectedStep!.operation === 'package' && this.$refs.packageComponent.dataValidation());
  }

  getUpdatedDate (item: StepList) {
    if(item.updatedAt) {
      return moment(item.updatedAt,'YYYY-MM-DD').format('D.M.YYYY')
    } else {
      return ''
    }
  }

  onCloseButtonClick () {
    this.saveIsProgress = true
    this.$router.push({ name: 'Production' })
  }

  isTabDisabled (idx: number): boolean {
    return idx > this.maxEnabledStep;
  }

  getActiveColor (step: StepList): string {
    if(step.order === this.tabs +1) {
      return 'background: white'
    } else {
      return ''
    }
  }

  get showSaveButton (): boolean {
    if(this.tabs + 2 >= this.selectedProduct!.productConfiguration!.length) {
      return false
    } else {
      return true
    }
  }

  get showNextButtonButton (): boolean {
    if(this.tabs + 1 >= this.selectedProduct!.productConfiguration!.length) {
      return false
    } else {
      return true
    }
  }

  getNextButtonLabel (): string {
    if(this.tabs + 2 === this.selectedProduct!.productConfiguration!.length) {
      return this.$t('productionPage.completeButtonLabel').toString()
    } else {
      return this.$t('productionPage.nextButtonLabel').toString()
    }
  }

  onResize () {
    if(this.$vuetify.breakpoint.smAndUp && this.$refs.title && this.$refs.cardTitle && this.$refs.footerRow) {
      const vh = window.innerHeight
      const titleHeight = this.$refs.title.scrollHeight
      const cardTitleHeight = this.$refs.cardTitle.scrollHeight
      const footerRowHeight = this.$refs.footerRow.scrollHeight
      this.cardHeight = `height: calc(${vh}px - 98px - ${titleHeight}px - ${cardTitleHeight}px - ${footerRowHeight}px)`
    } /*else if(this.$vuetify.breakpoint.xsOnly && this.$refs.title && this.$refs.cardTitle && this.$refs.footerRow && this.$refs.tabs) {
      const vh = window.innerHeight
      const titleHeight = this.$refs.title.scrollHeight
      const cardTitleHeight = this.$refs.cardTitle.scrollHeight
      const footerRowHeight = this.$refs.footerRow.scrollHeight
      const tabsHeight = this.$refs.tabs.scrollHeight
      this.cardHeight = `height: calc(${vh}px - 100px - ${titleHeight}px - ${cardTitleHeight}px - ${footerRowHeight}px - ${tabsHeight}px)`
    }*/
  }

  editedIngredientData (item: EditedIngredient[]): void {
    this.editedIngredientDataList = item
  }

  changeComment (item: string): void {
    this.comment = item
  }

  async onNextStepClick (next: boolean): Promise<void> {
    if(this.tabs + 1 === this.selectedProduct!.productConfiguration!.length) {
      this.$router.push({ name: 'Archive' })
    } else {
      if (this.selectedStep && this.selectedStep.productionStepId) {
        if(this.isProductionStepFermentationValid && this.isProductionStepLagerValid && this.isProductionStepPackageValid) {
          this.onUpDateProductionStep(next)
        }
      } else if (this.tabs === 0) {
        const payload: any = this.selectedProduct!.recipe!.id
        next ? this.saveNextIsProgress = true : this.saveIsProgress = true
        try {
          this.productionId = await this.createProduction(payload)
          next ? this.saveNextIsProgress = false : this.saveIsProgress = false
          if(this.isProductionStepFermentationValid && this.isProductionStepLagerValid && this.isProductionStepPackageValid) {
            this.onCreateProductionStep(next)
          }
        } catch (e) {
          this.snackBarMessage = this.$t('ingredientPage.errorData').toString()
          this.snackbarState = SnackbarStateEnum.Error
          this.showSnackbar = true
          next ? this.saveNextIsProgress = false : this.saveIsProgress = false
        } finally {
        }
      } else {
        if(this.isProductionStepFermentationValid && this.isProductionStepLagerValid && this.isProductionStepPackageValid) {
          this.onCreateProductionStep(next)
        }
      }
    }
  }

  async onCreateProductionStep (next: boolean): Promise<void> {
    let payloadData: ProductionStepIngredientListPayload[] = this.selectedProduct!.recipe!.recipeIngredient.map((item: RecipeIngredient) => {
      return {
        ingredientId: item.id,
        amount: item.amount.toFixed(2),
        comment: '',
        order: item.order,
        productionStepIngredientSpecificPropertieList: item.recipeIngredientSpecificPropertieList.map((i: RecipeIngredientSpecificPropertie) => {
          return {
            id: i.id!,
            name: i.name,
            unitId: i.unitId,
            order: i.order,
            amount: "0.00"
          }
        })
      }
    })

    this.editedIngredientDataList.forEach((item: EditedIngredient) => {
      let foundItem: ProductionStepIngredientListPayload | undefined = payloadData.find((i: ProductionStepIngredientListPayload) => i.ingredientId === item.ingredientId)
      if (foundItem) {
        foundItem.amount = (parseFloat(foundItem.amount) + item.amount).toFixed(2)
        foundItem.comment = item.comment
        foundItem.productionStepIngredientSpecificPropertieList = item.productionStepIngredientSpecificPropertieList.map((i: ProductionStepIngredientSpecificPropertieList) => {
          return {
            id: i.id,
            name: i.name,
            unitId: i.unitId,
            order: i.order,
            amount: i.amount != null &&  (i.amount >= 0 || i.amount <= 0) ? i.amount.toFixed(2) : null
          }
        })
      }
    })

    const payload: CreateProductionStepPayload = {
      productionId: this.productionId!,
      productStepId: this.selectedStep!.id,
      data: {
        comment: this.comment,
        productionStepIngredientList: this.selectedStep!.operation === 'product' ? payloadData : null,
        productionStepPrimaryFermentation: this.selectedStep!.operation === 'primary' ? this.fermentationData : null,
        productionStepLager: this.selectedStep!.operation === 'lager' ? this.lagerData : null,
        productionStepPackage: this.selectedStep!.operation === 'package' ? this.packageData : null,

      }
    }

    next ? this.saveNextIsProgress = true : this.saveIsProgress = true

    try {
      const response: Production = await this.createProductionStep(payload)
      this.snackBarMessage = this.$t('productionPage.dataIsSaved').toString()
      this.snackbarState = SnackbarStateEnum.Success
      this.showSnackbar = true
      this.stepList[this.tabs].productionStepId = response.productionStep![this.tabs].id
      this.stepList[this.tabs].updatedAt = response.productionStep![this.tabs].updatedAt
      if(next) {
        this.tabs = this.tabs + 1
      }
      this.maxEnabledStep = this.tabs
      this.productionNumber = response.productionNumber
      this.editedIngredientDataList = []
    } catch (e) {
      this.snackBarMessage = this.$t(`productionPage.${e.response.data.code}`).toString()
      this.snackbarState = SnackbarStateEnum.Error
      this.showSnackbar = true
    } finally {
      next ? this.saveNextIsProgress = false : this.saveIsProgress = false
    }
  }

  async onUpDateProductionStep (next: boolean): Promise<void> {
    let payloadData: ProductionStepIngredientListPayload[] = this.selectedProduct!.recipe!.recipeIngredient.map((item: RecipeIngredient) => {
      return {
        ingredientId: item.id,
        amount: item.amount.toFixed(2),
        comment: '',
        order: item.order,
        productionStepIngredientSpecificPropertieList: []
      }
    })

    this.editedIngredientDataList.forEach((item: EditedIngredient) => {
      let foundItem: ProductionStepIngredientListPayload | undefined = payloadData.find((i: ProductionStepIngredientListPayload) => i.ingredientId === item.ingredientId)
      if(foundItem) {
        foundItem.amount = (parseFloat(foundItem.amount) + item.amount).toFixed(2)
        foundItem.comment = item.comment
        foundItem.productionStepIngredientSpecificPropertieList = item.productionStepIngredientSpecificPropertieList.map((i: ProductionStepIngredientSpecificPropertieList) => {
          return {
            id: i.id,
            name: i.name,
            unitId: i.unitId,
            order: i.order,
            amount: i.amount != null &&  (i.amount >= 0 || i.amount <= 0) ? i.amount.toFixed(2) : null
          }
        })
      }
    })

    const payload: UpdateProductionStepPayload = {
      productionId: this.productionId!,
      productionStepId: this.selectedStep!.productionStepId!,
      data: {
        comment: this.comment,
        productionStepIngredientList: this.selectedStep!.operation === 'product' ? payloadData : null,
        productionStepPrimaryFermentation: this.selectedStep!.operation === 'primary' ? this.fermentationData : null,
        productionStepLager: this.selectedStep!.operation === 'lager' ? this.lagerData : null,
        productionStepPackage: this.selectedStep!.operation === 'package' ? this.packageData : null,
      }
    }

    next ? this.saveNextIsProgress = true : this.saveIsProgress = true

    try {
      await this.updateProductionStep(payload)
      this.snackBarMessage = this.$t('productPage.productUpdated').toString()
      this.snackbarState = SnackbarStateEnum.Success
      this.showSnackbar = true
      if(next) {
        this.tabs = this.tabs + 1
      }
      this.editedIngredientDataList = []
    } catch (e) {
      this.snackBarMessage = this.$t(`productionPage.${e.response.data.code}`).toString()
      this.snackbarState = SnackbarStateEnum.Error
      this.showSnackbar = true
    } finally {
      next ? this.saveNextIsProgress = false : this.saveIsProgress = false
    }
  }

  async fetchSelectedProduct (): Promise<void> {
    const payload: string = this.productId
    try {
      this.loading = true
      this.selectedProduct = await this.fetchProduct(payload)
      this.stepList = this.selectedProduct.productConfiguration!.map((item: ProductConfiguration) => {
        return {
          id: item.id,
          order: item.order,
          operation: item.operation,
          productId: item.productId,
          label: item.label,
          updatedAt: '',
          productionStepId: null
        }
      })
      this.selectedStep = this.stepList.length > 0 ? this.stepList[this.tabs] : null
    } catch (e) {
      this.snackBarMessage = this.$t('ingredientPage.errorData').toString()
      this.snackbarState = SnackbarStateEnum.Error
      this.showSnackbar = true
    } finally {
      this.loading = false
    }
  }

  async fetchProductionData (payload: string): Promise<void> {
    try {
      this.loading = true
      this.selectedProduction = await this.fetchProduction(payload)
      this.productionId = this.selectedProduction.id
      this.selectedProduct = this.selectedProduction.product
      this.productionNumber = this.selectedProduction.productionNumber
      this.stepList = this.selectedProduct.productConfiguration!.map((item: ProductConfiguration) => {
        const foundProductionStep: ProductionStepResponse | undefined = this.selectedProduction!.productionStep!.find((i: ProductionStepResponse) => i.order === item.order)
        if (foundProductionStep) {
          this.tabs = this.tabs + 1
          this.maxEnabledStep = this.tabs
        }
        return {
          id: item.id,
          order: item.order,
          operation: item.operation,
          productId: item.productId,
          label: item.label,
          updatedAt: foundProductionStep ? foundProductionStep.updatedAt : '',
          productionStepId: foundProductionStep ? foundProductionStep.id : null
        }
      })

      this.selectedStep = this.stepList.length > 0 ? this.stepList[this.tabs] : null

    } catch {
      this.snackBarMessage = this.$t('archivePage.errorData').toString()
      this.snackbarState = SnackbarStateEnum.Error
      this.showSnackbar = true
    } finally {
      this.loading = false
    }
  }

  onUpdateProductionNumberButtonClick ():void {
    this.showUpdateProductionNumberDialog = true
  }

  closeUpdateProductionNumberDialog (): void {
    this.showUpdateProductionNumberDialog = false
  }

  async saveProductionNumberClick (productionNumber: string): Promise<void> {
    const payload: updateProductionPayload = {
      productionId: this.productionId!,
      expirationDate: null,
      createdAt: null,
      productionNumber: productionNumber
    }

    try {
      this.loadingProductionNumber = true
      const response = await this.updateProduction(payload)
      this.showUpdateProductionNumberDialog = false
      this.productionNumber = response.productionNumber
      this.snackBarMessage = this.$t('archivePage.dataEdit').toString()
      this.snackbarState = SnackbarStateEnum.Success
      this.showSnackbar = true
    } catch (e) {
      this.snackBarMessage = this.$t('archivePage.errorSaveProduct').toString()
      this.snackbarState = SnackbarStateEnum.Error
      this.showSnackbar = true
    } finally {
      this.loadingProductionNumber = false
    }
  }

  mounted (): void {
    const selectedProductionId: any = this.$route.query ? this.$route.query.productionId : null
    if(selectedProductionId){
      this.fetchProductionData(selectedProductionId)
    } else {
      this.fetchSelectedProduct()
    }
  }

  updated () {
    this.onResize()
  }

}
