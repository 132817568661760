






























import Vue from 'vue'
import {Component, Prop} from 'vue-property-decorator'
import {SnackbarStateEnum} from "@/types/enums";
import i18n from "@/plugins/i18n";
import Production from "@/model/Production";

@Component
export default class UpdateProductionNumberDialog extends Vue {

  $refs!: {
    form: Vue & {
      validate(): boolean
    }
  }

  @Prop({ type: Boolean, default: false }) readonly show!: boolean
  @Prop({ type: Boolean, default: false }) readonly loadingProductionNumber!: boolean
  @Prop({type: String, required: true}) productionNumber!: string


  private snackbarState: SnackbarStateEnum = SnackbarStateEnum.Success
  private snackBarMessage: string = ''
  private showSnackbar: boolean = false
  private isValid: boolean = false
  private updatedProductionNumber: string = ''
  private updatedProductionYear: string = ''
  private rules: Array<(v: string) => string | boolean> = [
    (v: string) => !!v || i18n.t('loginPage.valueIsRequiredMessage').toString(),
  ]
  private splitNumber: string[] | null = []

  get snackbarColor (): boolean {
    return this.snackbarState === SnackbarStateEnum.Success
  }

  get getSplitedNumber ():any {
    this.splitNumber = this.productionNumber.split("/");
    if(this.splitNumber) {
      return this.splitNumber[1]
    }
  }

  onCloseDialog (): void {
    this.$emit('close')
  }

  onSaveClick () {
    const productionNumber: string = this.updatedProductionNumber + '/' + this.updatedProductionYear
    this.$emit('saved',productionNumber)
  }

  mounted (): void {
    this.splitNumber = this.productionNumber.split("/");
    if(this.splitNumber && this.splitNumber[0] &&  this.splitNumber[1]) {
      this.updatedProductionNumber = this.splitNumber[0]
      this.updatedProductionYear =  this.splitNumber[1]
    }
  }
}
