import Vue from 'vue'
import Vuex, { Store, StoreOptions } from 'vuex'
import i18n from '@/plugins/i18n'
import vuetify from '@/plugins/vuetify'
import { AuthState, GlobalState } from '@/types/state'
import * as getterTypes from '@/store/getter-types'
import * as mutationTypes from '@/store/mutation-types'
import * as actionTypes from '@/store/action-types'
import authModule from '@/store/modules/auth'
import Language from '@/model/Language'
import { CURRENT_LANGUAGE_FIELD } from '@/constants'
import Unit from "@/model/Unit";
import axios from "@/plugins/axios";
import Ingredient from "@/model/Ingredient";
import {
  CreateProductionStepPayload, DeleteProductionPayload,
  NewIngredientPayload,
  NewProductPayload, PackagePayload, PeriodFilterPayload, TankPayload,
  UpdatedIngredientPayload, updateProductionPayload, UpdateProductionStepPayload,
  UpdateProductPayload,
  UpdateProductStatusPayload, YeastNamePayload
} from '@/types/payload'
import Product from "@/model/Product";
import Production from "@/model/Production";
import {DashboardResponse, ProductionStepResponse} from "@/types/response";
import YeastType from "@/model/YeastType";
import PackageList from "@/model/PackageList";
import TankList from "@/model/Tanklist";
import YeastName from '@/model/YeastName'
import TankType from '@/model/TankType'

Vue.use(Vuex)

const supportedLanguages: Language[] = [
  Language.create('sk', i18n.t('languages.slovakLanguageLabel').toString(), false),
  Language.create('en', i18n.t('languages.englishLanguageLabel').toString(), false),
]

const state = (): GlobalState => ({
  auth: {} as AuthState,
  showAppDrawer: undefined,
  appUpdateAvailable: false,
  supportedLanguages,
  currentUiLanguage: supportedLanguages[0]
})

const storeOptions: StoreOptions<any> = {
  modules: {
    auth: authModule
  },

  state,

  getters: {
    [getterTypes.APP_DRAWER_STATE] (state: GlobalState): boolean {
      return !!state.showAppDrawer
    },

    [getterTypes.IS_APP_UPDATE_AVAILABLE] (state: GlobalState): boolean {
      return state.appUpdateAvailable
    },

    [getterTypes.SUPPORTED_LANGUAGES] (state: GlobalState): Language[] {
      return state.supportedLanguages
    },

    [getterTypes.CURRENT_UI_LANGUAGE] (state: GlobalState): Language {
      return state.currentUiLanguage
    },
  },

  mutations: {
    [mutationTypes.TOGGLE_APP_DRAWER_MUTATION] (state: GlobalState): void {
      state.showAppDrawer = !state.showAppDrawer
    },

    [mutationTypes.SET_DRAWER_STATE_MUTATION] (state: GlobalState, payload: boolean): void {
      state.showAppDrawer = payload
    },

    [mutationTypes.APP_UPDATE_IS_AVAILABLE] (state: GlobalState): void {
      state.appUpdateAvailable = true
    },

    [mutationTypes.APP_UPDATE_IS_AVAILABLE] (state: GlobalState): void {
      state.appUpdateAvailable = true
    },

    [mutationTypes.SET_CURRENT_UI_LANGUAGE] (state: GlobalState, payload: Language): void {
      state.currentUiLanguage = payload
      localStorage.setItem(CURRENT_LANGUAGE_FIELD, payload.code)
    },
  },

  actions: {
    async [actionTypes.INIT_STORE] ({ commit, dispatch }: { commit: Function, dispatch: Function }): Promise<void> {
      const storedLanguageCode: string | null = localStorage.getItem(CURRENT_LANGUAGE_FIELD)

      if (storedLanguageCode) {
        const selectedLanguage: Language | undefined  = (this.getters[getterTypes.SUPPORTED_LANGUAGES] as Language[]).find(
          (l: Language) => l.code === storedLanguageCode
        )

        if (selectedLanguage) {
          commit(mutationTypes.SET_CURRENT_UI_LANGUAGE, selectedLanguage)
        }
      }

      await Promise.all([
        dispatch(actionTypes.AUTH_INIT_MODULE),
      ])
    },

    [actionTypes.TOGGLE_APP_DRAWER] ({ commit }: { commit: Function }): void {
      commit(mutationTypes.TOGGLE_APP_DRAWER_MUTATION)
    },

    [actionTypes.SET_DRAWER_STATE] ({ commit }: { commit: Function }, payload: boolean): void {
      commit(mutationTypes.SET_DRAWER_STATE_MUTATION, payload)
    },

    [actionTypes.SWITCH_APPLICATION_LANGUAGE] ({ commit }: { commit: Function }, payload: Language): void {
      vuetify.framework.lang.current = payload.code
      vuetify.framework.rtl = payload.rtl

      i18n.locale = payload.code

      if (this.getters[getterTypes.CURRENT_UI_LANGUAGE] !== payload) {
        commit(mutationTypes.SET_CURRENT_UI_LANGUAGE, payload)
      }
    },

    async [actionTypes.FETCH_UNIT_LIST] ({ }: { commit: Function }): Promise<Unit[]> {

      const response = await axios.get('/unit')

      return Unit.createFromResponseList(response.data)
    },

    async [actionTypes.FETCH_INGREDIENTS_LIST] ({ }: { commit: Function }): Promise<Ingredient[]> {

      const response = await axios.get('/ingredient')

      return Ingredient.createFromResponseList(response.data)
    },

    async [actionTypes.CREATE_INGREDIENT] ({ }, payload: NewIngredientPayload): Promise<void> {
      const response = await axios.post('/ingredient', payload)

      response.data
    },

    async [actionTypes.DELETE_INGREDIENT] ({ }, payload: string): Promise<void> {

      const response = await axios.delete(`/ingredient/${payload}`)

      return response.data

    },

    async [actionTypes.UPDATE_INGREDIENT]({ }, payload: UpdatedIngredientPayload): Promise<void> {

      const response = await axios.put('/ingredient', payload)

      response.data
    },

    async [actionTypes.FETCH_PRODUCTS_LIST] ({ commit }: { commit: Function }, payload: boolean): Promise<Product[]> {

      const response = await axios.get('/product', {
        params: {
          isArchived: payload
        }
      })

      return Product.createFromResponseList(response.data)
    },

    async [actionTypes.FETCH_PRODUCTS_LIST_WITH_IMAGES] ({ commit }: { commit: Function }): Promise<Product[]> {

      const response = await axios.get('/product/images')

      return Product.createFromResponseList(response.data)
    },

    async [actionTypes.FETCH_PRODUCT_IMAGE] ({ commit }: { commit: Function }, payload: string): Promise<string> {

      const response = await axios.get(`/product/${payload}/image`)

      return response.data
    },

    async [actionTypes.CREATE_PRODUCT] ({ }, payload: NewProductPayload): Promise<Product> {
      const response = await axios.post('/product', payload)

      return Product.createFromResponse(response.data)
    },

    async [actionTypes.UPDATE_PRODUCT] ({ }, payload: UpdateProductPayload): Promise<Product> {
      const response = await axios.post(`/product/${payload.productId}/version`, payload.data)

      return Product.createFromResponse(response.data)
    },

    async [actionTypes.FETCH_PRODUCT] ({ commit }: { commit: Function },payload: string): Promise<Product> {

      const response = await axios.get(`/product/${payload}`)

      return Product.createFromResponse(response.data)
    },

    async [actionTypes.UPDATE_PRODUCT_STATUS]({ }, payload: UpdateProductStatusPayload): Promise<Product> {

      const response = await axios.put('/product', payload)

      return Product.createFromResponse(response.data)
    },

    async [actionTypes.CREATE_PRODUCTION] ({ }, payload: string): Promise<string> {
      const response = await axios.post(`/production/${payload}`)

      return response.data.id
    },

    async [actionTypes.CREATE_PRODUCTION_STEP] ({ }, payload: CreateProductionStepPayload): Promise<Production> {
      const response = await axios.post(`/production/${payload.productionId}/step/${payload.productStepId}`,payload.data)

      return Production.createFromResponse(response.data)
    },

    async [actionTypes.FETCH_PRODUCTION_BY_ID] ({ commit }: { commit: Function },payload: string): Promise<Production> {

      const response = await axios.get(`/production/${payload}`)

      return Production.createFromResponse(response.data)
    },

    async [actionTypes.UPDATE_PRODUCTION_STEP]({ }, payload: UpdateProductionStepPayload): Promise<Production> {

      const response = await axios.put(`/production/${payload.productionId}/step/${payload.productionStepId}`, payload.data)

      return Production.createFromResponse(response.data)
    },

    async [actionTypes.FETCH_PRODUCTION_STEP_BY_ID] ({ commit }: { commit: Function },payload: string): Promise<ProductionStepResponse> {

      const response = await axios.get(`/production/step/${payload}`)

      return response.data
    },

    async [actionTypes.FETCH_ARCHIVE_LIST] ({ }: { commit: Function }, payload?: PeriodFilterPayload): Promise<Production[]> {

      const response = await axios.get('/archive',{
        params: {
          periodStart: payload ? payload.periodStart : null,
          periodEnd: payload ? payload.periodEnd : null
        }
      })

      return response.data
    },

    async [actionTypes.DELETE_PRODUCTION_BY_ID] ({ }, payload: DeleteProductionPayload): Promise<void> {

      const response = await axios.delete(`/production/${payload.productionId}`,
        {
          data: {
            deleteMessage: payload.deleteMessage
          }
        }
      )

      return response.data
    },

    async [actionTypes.UPDATE_PRODUCTION]({ }, payload: updateProductionPayload): Promise<Production> {

      const response = await axios.put(`/production/${payload.productionId}`, null,{
        params: {
          expirationDate: payload.expirationDate,
          createdAt: payload.createdAt,
          productionNumber: payload.productionNumber
        }
      })

      return response.data
    },

    async [actionTypes.FETCH_DASHBOARD_LIST] ({ }: { commit: Function }): Promise<DashboardResponse[]> {

      const response = await axios.get('/dashboard')

      return response.data
    },

    async [actionTypes.FETCH_YEAST_TYPE] ({ }: { commit: Function }): Promise<YeastType[]> {

      const response = await axios.get('/yeast/yeast-type')

      return YeastType.createFromResponseList(response.data)
    },

    async [actionTypes.FETCH_YEAST_NAME_LIST] ({ }: { commit: Function }): Promise<YeastName[]> {

      const response = await axios.get('/yeast')

      return YeastName.createFromResponseList(response.data)
    },

    async [actionTypes.CREATE_YEAST_NAME] ({ }, payload: YeastNamePayload): Promise<YeastName> {
      const response = await axios.post(`/yeast`, payload)

      return YeastName.createFromResponse(response.data)
    },

    async [actionTypes.UPDATE_YEAST_NAME]({ }, payload: YeastNamePayload): Promise<YeastName> {

      const response = await axios.put(`/yeast/${payload.id}`,{
        label: payload.label,
      })

      return YeastName.createFromResponse(response.data)
    },

    async [actionTypes.DELETE_YEAST_NAME] ({ }, payload: string): Promise<void> {

      const response = await axios.delete(`/yeast/${payload}`)

      return response.data
    },

    async [actionTypes.FETCH_PACKAGE_LIST] ({ }: { commit: Function }): Promise<PackageList[]> {

      const response = await axios.get('/package')

      return PackageList.createFromResponseList(response.data)
    },

    async [actionTypes.CREATE_PACKAGE] ({ }, payload: PackagePayload): Promise<PackageList> {
      const response = await axios.post(`/package`, payload)

      return PackageList.createFromResponse(response.data)
    },

    async [actionTypes.UPDATE_PACKAGE]({ }, payload: PackagePayload): Promise<PackageList> {

      const response = await axios.put(`/package/${payload.id}`,{
        label: payload.label,
        volume: payload.volume
      })

      return PackageList.createFromResponse(response.data)
    },

    async [actionTypes.DELETE_PACKAGE] ({ }, payload: string): Promise<void> {

      const response = await axios.delete(`/package/${payload}`)

      return response.data
    },

    async [actionTypes.FETCH_TANK_TYPE_LIST] ({ }: { commit: Function }): Promise<TankType[]> {

      const response = await axios.get('/tank/tank-type')

      return TankType.createFromResponseList(response.data)
    },

    async [actionTypes.FETCH_TANK_LIST] ({ }: { commit: Function }): Promise<TankList[]> {

      const response = await axios.get('/tank')

      return TankList.createFromResponseList(response.data)
    },

    async [actionTypes.CREATE_TANK] ({ }, payload: TankPayload): Promise<TankList> {
      const response = await axios.post(`/tank`, payload)

      return TankList.createFromResponse(response.data)
    },

    async [actionTypes.UPDATE_TANK]({ }, payload: TankPayload): Promise<TankList> {

      const response = await axios.put(`/tank/${payload.id}`,{
        label: payload.label,
        typeId: payload.typeId
      })

      return TankList.createFromResponse(response.data)
    },

    async [actionTypes.DELETE_TANK] ({ }, payload: string): Promise<void> {

      const response = await axios.delete(`/tank/${payload}`)

      return response.data
    },

    async [actionTypes.UPDATE_PRODUCT_IMAGE]({ }, payload: any): Promise<any> {

      const response = await axios.put(`/product/${payload.productId}/update-image`,{
          image: payload.image,
      })

      return response.data
    },
  }
}

export default new Store(storeOptions)
