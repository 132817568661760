



















































































































import Vue from 'vue'
import _ from 'lodash'
import { Component, Prop } from 'vue-property-decorator'
import {SnackbarStateEnum} from "@/types/enums";
import {Action} from "vuex-class";
import {
  CREATE_PRODUCT,
  FETCH_INGREDIENTS_LIST,
  FETCH_PRODUCT,
  UPDATE_PRODUCT, UPDATE_PRODUCT_IMAGE,
  UPDATE_PRODUCT_STATUS
} from "@/store/action-types";
import Ingredient from "@/model/Ingredient";
import {IngredientPlaceholder} from "@/types/types";
import {
  NewProductPayload,
  RecipeIngredientListPayload, UpdateImagePayload,
  UpdateProductPayload,
  UpdateProductStatusPayload
} from "@/types/payload";
import i18n from "@/plugins/i18n";
import Product from "@/model/Product";
import RecipeIngredient from "@/model/RecipeIngredient";
import IngredientProductDetail from "@/components/IngredientProductDetail.vue";

interface EventFile extends Event {
  target: HTMLInputElement & EventTarget
}

@Component({
  components: {
    IngredientProductDetail
  }
})
export default class AddNewProductDialog extends Vue {

  $refs!: {
    form: Vue & {
      validate(): boolean
    }
    fileInput: HTMLElement
  }

  @Prop({ type: Boolean, default: false }) readonly show!: boolean
  @Prop({ type: Object || null, default: false }) readonly selectedProduct!: Product
  @Action(FETCH_INGREDIENTS_LIST) fetchIngredientList!: { (): Promise<Ingredient[]> }
  @Action(CREATE_PRODUCT) saveNewProduct!: { (payload: NewProductPayload): Promise<Product> }
  @Action(FETCH_PRODUCT) fetchProduct!: { (payload: string): Promise<Product> }
  @Action(UPDATE_PRODUCT) updateProduct!: { (payload: UpdateProductPayload): Promise<Product> }
  @Action(UPDATE_PRODUCT_STATUS) updateProductStatus!: { (payload: UpdateProductStatusPayload): Promise<Product> }
  @Action(UPDATE_PRODUCT_IMAGE) saveImage!: { (payload: UpdateImagePayload): Promise<any> }

  private showSnackbar: boolean = false
  private snackBarMessage: string = ''
  private snackbarState: SnackbarStateEnum = SnackbarStateEnum.Success
  private nameProductRules: Array<(v: string) => string | boolean> = [
    (v: string) => !!v || i18n.t('loginPage.valueIsRequiredMessage').toString(),
  ]
  private unitRules: Array<(v: string) => string | boolean> = [
    (v: string) => !!v || i18n.t('loginPage.valueIsRequiredMessage').toString(),
  ]
  private amountRules: Array<(v: string) => string | boolean> = [
    (v: string) => !!v || i18n.t('loginPage.valueIsRequiredMessage').toString(),
  ]
  private isValid: boolean = false
  private headers: any[]= [
    { text: this.$t('productPage.ingredient').toString(), align: 'start', sortable: false, value: 'name' },
    { text: this.$t('productPage.tableAmount').toString(), align: 'start', sortable: false, value: 'amount' },
    { text: this.$t('productPage.unit').toString() , align: 'center', sortable: false, value: 'unit' },
    { text: this.$t('productPage.delete').toString(), align: 'center', sortable: false, value: 'action' ,action: 'required'},
  ]
  private ingredientList: IngredientPlaceholder[] = []
  private usedIngredientList: IngredientPlaceholder[] = []
  private ingredients: Ingredient[] = []
  private fetchInProgress: boolean = false
  private showEdit: boolean = false
  private image: string = ''

  get getTitle (): string {
    if(this.isEdit){
      return this.$t('productPage.editProductTitle').toString()
    } else {
      return this.$t('productPage.addNewProductTitle').toString()
    }
  }

  get isEdit (): boolean {
    return !!this.selectedProduct.id
  }

  get snackbarColor (): boolean {
    return this.snackbarState === SnackbarStateEnum.Success
  }

  onEditClick (): void {
    this.showEdit = true
  }

  onCloseDialog(): void {
    this.$emit('close')
  }

  onAddImageClick (): void {
    this.$refs.fileInput.click()
  }

  onFileChange (e: EventFile): void {
    if (e && e.target.files) {
      const files: File | undefined = e.target.files[0]

      if (files) {
        const reader = new FileReader()

        reader.readAsDataURL(files)
        reader.onload = (e: any) => {
          if (e.target) {

            const img = new Image()
            // @ts-ignore
            img.src = e.target.result
            img.onload = () => {
              const elem = document.createElement('canvas')
              const scaleFactor = 288 / img.width
              elem.width = 288
              elem.height = img.height * scaleFactor
              const ctx = elem.getContext('2d')

              ctx!.drawImage(img, 0, 0, 288, img.height * scaleFactor)
              ctx!.canvas.toBlob((blob: any) => {
                new File([blob], 'image', {
                  type: 'image/png',
                  lastModified: Date.now()
                })
              }, 'image/jpeg', 1)
              const image: string = elem.toDataURL('image/png', 1)
              //let imageArray = image.split(/:|,|;/)
              this.image = image
              if(this.isEdit) {
                this.onSaveUpdatedImage()
              }
            }
          }
        }
      }
    }
  }

  async onSaveUpdatedImage (): Promise<void> {
    const payload: UpdateImagePayload = {
      productId: this.selectedProduct.id,
      image: this.image
    }
    try {
      await this.saveImage(payload)
    } catch (e) {

    }
  }

  createNewItem (): void {
    if (this.ingredientList.length > 0) {
      let maxIndex: IngredientPlaceholder | undefined = _.maxBy(this.ingredientList, 'idx')
      if (maxIndex)
        this.ingredientList.push({
          idx: maxIndex.idx + 1,
          name: '',
          ingredientId: null,
          amount: 0.00,
          required: true,
          unit: '',
          isRecipeIngredient: false
        })
    } else {
      this.ingredientList.push({ idx: 1, name: '', ingredientId: null, amount: 0.00, required: true, unit: '', isRecipeIngredient: false})
    }
  }

  onIngredientSelect(item: IngredientPlaceholder): void {
    const index = this.ingredientList.indexOf(item)
    const foundIngredient: Ingredient | undefined = this.ingredients.find((ingredient: Ingredient) => ingredient.id === item.ingredientId)
    if(foundIngredient) {
      this.ingredientList[index].unit = foundIngredient.unit.symbol
    }
  }

  deleteItem(item: IngredientPlaceholder): void {
    const index = this.ingredientList.indexOf(item)
    if(this.ingredientList.length > 1) {
      this.ingredientList.splice(index, 1)
    }
  }

  async onArchiveProduct(): Promise<void> {
    const payload:UpdateProductStatusPayload = {
      id: this.selectedProduct.id,
      isArchived: true
    }
    try{
      await this.updateProductStatus(payload)
      this.$emit('archived')
    } catch (e) {
    }
  }

  async onActiveProduct(): Promise<void> {
    const payload:UpdateProductStatusPayload = {
      id: this.selectedProduct.id,
      isArchived: false
    }
    try{
      const response: Product = await this.updateProductStatus(payload)
      this.$emit('activated',response)
    } catch (e) {
    }
  }

  async fetchIngredientsList(): Promise<void> {
    this.fetchInProgress = true
    try {
      this.ingredients = await this.fetchIngredientList()
    } catch (e) {
      this.snackBarMessage = this.$t('ingredientPage.errorData').toString()
      this.snackbarState = SnackbarStateEnum.Error
      this.showSnackbar = true

    } finally {
      this.fetchInProgress = false
    }
  }

  async saveButtonClick(): Promise<void> {
    if (this.$refs.form.validate()) {
      const recipeIngredientList: RecipeIngredientListPayload[] = []
      this.ingredientList.forEach((item: IngredientPlaceholder) => {
        if(!item.isRecipeIngredient) {
          recipeIngredientList.push({ ingredientId: item.ingredientId!, amount: parseFloat(item.amount.toString()).toFixed(2) })
        }
      })

      const usedRecipeIngredientList: RecipeIngredientListPayload[] = []
      this.ingredientList.forEach((item: IngredientPlaceholder) => {
        if(item.isRecipeIngredient) {
          usedRecipeIngredientList.push({ ingredientId: item.ingredientId!, amount: parseFloat(item.amount.toString()).toFixed(2) })
        }
      })

      if(this.isEdit) {
        const payload: UpdateProductPayload = {
          productId: this.selectedProduct.id,
          data: {
            recipeIngredientList: recipeIngredientList,
            usedRecipeIngredientList: usedRecipeIngredientList
          }
        }
        try {
          this.fetchInProgress = true
          const response: Product = await this.updateProduct(payload)
          this.$emit('updated', response)
        } catch (e) {
          this.snackBarMessage = this.$t('productPage.errorSaveProduct').toString()
          this.snackbarState = SnackbarStateEnum.Error
          this.showSnackbar = true
        } finally {
          this.fetchInProgress = false
        }
      } else {
        const payload: NewProductPayload = {
          name: this.selectedProduct.name,
          image: this.image,
          recipeIngredientList: recipeIngredientList
        }
        try {
          this.fetchInProgress = true
          const response: Product = await this.saveNewProduct(payload)
          this.$emit('saved', response)
        } catch (e) {
          this.snackBarMessage = this.$t('productPage.errorSaveProduct').toString()
          this.snackbarState = SnackbarStateEnum.Error
          this.showSnackbar = true
        } finally {
          this.fetchInProgress = false
        }
      }
    }
  }

  mounted(): void {
    this.fetchIngredientsList()
    if(this.isEdit) {
      this.ingredientList = this.selectedProduct.recipe!.recipeIngredient.map((item: RecipeIngredient) => {
        return { idx: item.order, name: item.name, ingredientId: item.id, amount: parseFloat(item.amount.toFixed(2)), required: true, unit: item.unit.symbol, isRecipeIngredient: true }
      })
    } else {
      this.ingredientList = [
        { idx: 1, name:'', ingredientId: null, amount: 0.00, required: true, unit: '', isRecipeIngredient: false },
        { idx: 2, name:'', ingredientId: null, amount: 0.00, required: true, unit: '', isRecipeIngredient: false },
        { idx: 3, name:'', ingredientId: null, amount: 0.00, required: true, unit: '', isRecipeIngredient: false }
      ]
    }
  }

}
