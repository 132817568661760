import {ProductConfigurationResponse} from "@/types/response";

export default class ProductConfiguration {
  private constructor (
    readonly id: string,
    readonly productId: string,
    readonly order: number,
    readonly operation: string,
    readonly label: string,
    readonly createdAt: string
  ) {}

  public static createFromResponseList (response: ProductConfigurationResponse[]): ProductConfiguration[] {
      return response.map((item: ProductConfigurationResponse) => {
          return new ProductConfiguration(
              item.id,
              item.productId,
              item.order,
              item.operation,
              item.label,
              item.createdAt
          )
      })
  }
}
