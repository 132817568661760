import { Route } from 'vue-router'
import store from '@/store'
import { IS_USER_LOGGED_IN } from '@/store/getter-types'

export const rootPageGuard = (to: Route, from: Route, next: Function): void => {
  if (store.getters[IS_USER_LOGGED_IN]) {
    next('/dashboard')
  } else {
    next('/login')
  }
}

export const loginPageGuard = (to: Route, from: Route, next: Function): void => {
  if (store.getters[IS_USER_LOGGED_IN]) {
    next('/dashboard')
  } else {
    next()
  }
}

export const isUserLoggedInGuard = (to: Route, from: Route, next: Function): void => {
  if (store.getters[IS_USER_LOGGED_IN]) {
    next()
  } else {
    next('/login')
  }
}
