import {YeastTypeResponse} from "@/types/response";

export default class YeastType {
  private constructor (
    readonly id: string,
    readonly label: string,
    readonly isGeneration: boolean

  ) {}

  public static createFromResponseList (response: YeastTypeResponse[]): YeastType[] {
      return response.map((item: YeastTypeResponse) => {
          return new YeastType(
              item.id,
              item.label,
              item.isGeneration
          )
      })
  }

    public static createFromResponse (response: YeastTypeResponse): YeastType {
        return new YeastType(
            response.id,
            response.label,
            response.isGeneration
        )
    }
}
