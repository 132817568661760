













































































import Vue from 'vue'
import {Component, Prop} from 'vue-property-decorator'
import {SnackbarStateEnum} from "@/types/enums";
import {Action} from "vuex-class";
import {FETCH_UNIT_LIST, UPDATE_INGREDIENT} from "@/store/action-types";
import Unit from "@/model/Unit";
import {UpdatedIngredientPayload} from "@/types/payload";
import i18n from "@/plugins/i18n";
import Ingredient from "@/model/Ingredient";
import SpecificPropertieList from "@/model/SpecificPropertieList";
import _ from "lodash";
import { UnitListItem } from '@/types/types'

@Component
export default class EditIngredientDialog extends Vue {

  $refs!: {
    form: Vue & {
      validate(): boolean
    }
  }

  @Prop({ type: Boolean, default: false }) readonly show!: boolean
  @Prop({ type: Object, default: false }) readonly selectedIngredient!: Ingredient
  @Prop({ type: Boolean, default: false }) readonly deleteIngredientLoading!: boolean
  @Action(FETCH_UNIT_LIST) fetchUnitList!: { (): Promise<Unit[]> }
  @Action(UPDATE_INGREDIENT) saveIngredient!: { (payload: UpdatedIngredientPayload): Promise<void> }

  private snackbarState: SnackbarStateEnum = SnackbarStateEnum.Success
  private snackBarMessage: string = ''
  private showSnackbar: boolean = false
  private fetchInProgress: boolean = false
  private isValid: boolean = false
  private selectedItem: UnitListItem | null = null
  private selectedUnitSymbol: UnitListItem | null = null
  private unitList: UnitListItem[]  = []
  private selectedUnit: Unit | null = null
  private specificPropertieList: SpecificPropertieList[] = []
  private nameIngredientRules: Array<(v: string) => string | boolean> = [
    (v: string) => !!v || i18n.t('loginPage.valueIsRequiredMessage').toString(),
  ]
  private unitIngredientRules: Array<(v: string) => string | boolean> = [
    (v: string) => !!v || i18n.t('loginPage.valueIsRequiredMessage').toString(),
  ]
  private name: string | null = null

  get snackbarColor (): boolean {
    return this.snackbarState === SnackbarStateEnum.Success
  }

  createNewItem (): void {
    if (this.specificPropertieList.length > 0) {
      let maxOrder: SpecificPropertieList | undefined = _.maxBy(this.specificPropertieList, 'order')
      if (maxOrder)
        this.specificPropertieList.push({
          id: null,
          name: "",
          unitId: "",
          order: maxOrder.order + 1,
          unit: undefined
        })
    } else {
      this.specificPropertieList.push({
        id: null,
        name: "",
        unitId: "",
        order: 1,
        unit: undefined
      })
    }
  }

  deleteItem (i: SpecificPropertieList): void {
    const index = this.specificPropertieList.indexOf(i)
    this.specificPropertieList.splice(index, 1)
  }

  onCloseDialog() {
    this.$emit('close')
  }

  onDeleteIngredient() {
    this.$emit('delete')
  }

  getUnitSymbol (unitId: string): string {
    //i.selectedUnit.symbol
    if(this.unitList) {
      let foundSymbol: string = ''
      this.unitList.forEach((item: UnitListItem) => {
        const foundUnit: Unit | undefined = item.unitList.find((item: Unit) => item.id === unitId)
        if(foundUnit){
          foundSymbol = foundUnit.symbol
        }
      })
      return foundSymbol
    } else {
      return ''
    }
  }

  onSelectedUnitChange (unit: Unit): void {
    this.selectedUnit = unit
  }

  onSelectedPropertieUnitChange (unit: Unit, item: any): void {
    item.unitId = unit.id
  }

  async onSaveClick(): Promise<void> {
     if (this.$refs.form.validate()) {
       let payload: UpdatedIngredientPayload = {
         name: this.name!,
         id: this.selectedIngredient.id,
         unitId: this.selectedUnit!.id,
         specificPropertieList: this.specificPropertieList
       }

       try {
         this.fetchInProgress = true
         await this.saveIngredient(payload)
         this.$emit('saved')
       } catch (e) {
         this.snackBarMessage = this.$t(`ingredientPage.${e.response.data.code}`).toString()
         this.snackbarState = SnackbarStateEnum.Error
         this.showSnackbar = true
       } finally {
         this.fetchInProgress = false
       }
     }
  }

  async fetchUnit() {
    try {
      const response: Unit[] = await this.fetchUnitList()
      //@ts-ignore
      this.unitList = _.chain(response)
          .groupBy("kind")
          .map((value: Unit, key: string) => ({ kind: key, unitList: value }))
          .value()

      if(this.selectedIngredient && this.selectedIngredient.unit) {
        const foundUnit: UnitListItem | undefined = this.unitList.find((item: UnitListItem) => item.kind === this.selectedIngredient.unit.kind)
        this.selectedItem = foundUnit ? foundUnit : null
        this.selectedUnit = this.selectedIngredient.unit
        this.selectedUnitSymbol = foundUnit ? foundUnit : null
      }

    } catch (e) {

    } finally {

    }
  }

  mounted() {
    this.name = this.selectedIngredient.name
    this.specificPropertieList = this.selectedIngredient.specificPropertieList.map((item: SpecificPropertieList) => {
      return {
          id: item.id,
          name: item.name,
          unitId: item.unitId,
          order: item.order,
          unit: item.unit
      }
    })
    this.fetchUnit()
  }
}
