import {IngredientsResponse} from "@/types/response";
import Unit from "@/model/Unit";
import SpecificPropertieList from "@/model/SpecificPropertieList";

export default class Ingredient {
  private constructor (
        readonly createdAt: string,
        readonly id: string,
        readonly name: string,
        readonly unit: Unit,
        readonly unitId: string,
        readonly specificPropertieList: SpecificPropertieList[]
  ) {}

  public static createFromResponseList (response: IngredientsResponse[]): Ingredient[] {
      return response.map((item: IngredientsResponse) => {
          return new Ingredient(
              item.createdAt,
              item.id,
              item.name,
              Unit.createFromResponse(item.unit),
              item.unitId,
              item.specificPropertieList ? SpecificPropertieList.createFromResponseList(item.specificPropertieList) : []
          )
      })
  }

    public static createFromResponse (response: IngredientsResponse): Ingredient {
        return new Ingredient(
            response.createdAt,
            response.id,
            response.name,
            Unit.createFromResponse(response.unit),
            response.unitId,
            response.specificPropertieList ? SpecificPropertieList.createFromResponseList(response.specificPropertieList) : []
        )
    }
}
