































import Vue from 'vue'
import { Component, Watch } from 'vue-property-decorator'
import { TranslateResult } from 'vue-i18n'
import { IS_USER_LOGGED_IN } from '@/store/getter-types'
import { AUTH_LOG_USER_IN } from '@/store/action-types'
import InvalidCredentialsError from '@/model/error/InvalidCredentialsError'
import { UserCredentialsPayload } from '@/types/payload'
import LanguageSelectionButton from '@/components/LanguageSelecionButton.vue'
import i18n from '@/plugins/i18n'
import { Action, Getter } from 'vuex-class'

function valueIsRequired (v: string): boolean | TranslateResult {
  return !!v || i18n.t('loginPage.valueIsRequiredMessage')
}

@Component({
  components: {
    LanguageSelectionButton
  }
})
export default class LoginPage extends Vue {
  $refs!: {
    form: Vue & { validate (): boolean }
  }

  @Getter(IS_USER_LOGGED_IN) isUserLoggedIn!: boolean

  @Action(AUTH_LOG_USER_IN) performLogin!: { (payload: UserCredentialsPayload): Promise<void> }

  private loginName: string | null = null
  private password: string | null = null
  private showPassword: boolean = false
  private formIsValid: boolean = false
  private showErrorSnackbar: boolean = false
  private errorMessage: string | null = null
  private loginInProgress: boolean = false

  private userNameValidationRules = [
    valueIsRequired
  ]

  private passwordValidationRules = [
    valueIsRequired
  ]

  get passwordFieldType (): string {
    return this.showPassword ? 'text' : 'password'
  }

  get passwordFieldIcon (): string {
    return this.showPassword ? 'fa-eye-slash' : 'fa-eye'
  }

  @Watch('isUserLoggedIn')
  onIsUserLoggedInChange (newVal: boolean): void {
    if (newVal) {
      this.$router.push({ name: 'Dashboard' })
    }
  }

  onToggleShowPasswordClick (): void {
    this.showPassword = !this.showPassword
  }

  onFormSubmit (): void {
    if (this.$refs.form.validate()) {
      const payload: UserCredentialsPayload = {
        loginName: this.loginName!,
        password: this.password!
      }
      this.loginInProgress = true
      this.performLogin(payload)
        .catch((e: InvalidCredentialsError) => {
          this.errorMessage = e.message
          this.showErrorSnackbar = true
          this.loginInProgress = false
        })
    }
  }
}

