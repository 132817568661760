var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"persistent":"","width":"1000","scrollable":"","fullscreen":_vm.$vuetify.breakpoint.smAndDown},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c('v-snackbar',{attrs:{"color":_vm.snackbarColor ? 'success' : 'error'},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"dark":"","text":"","icon":""},on:{"click":function($event){_vm.showSnackbar = false}}},'v-btn',attrs,false),[_c('v-icon',{on:{"click":function($event){_vm.showSnackbar = false}}},[_vm._v("far fa-times-circle")])],1)]}}]),model:{value:(_vm.showSnackbar),callback:function ($$v) {_vm.showSnackbar=$$v},expression:"showSnackbar"}},[_vm._v(_vm._s(_vm.snackBarMessage))]),_c('v-card',[_c('v-card-title',{staticClass:"px-0 pt-0"},[_c('v-toolbar',{attrs:{"color":"primary"}},[_c('v-btn',{attrs:{"text":"","fab":"","icon":""},on:{"click":_vm.onCloseDialog}},[_c('v-icon',{attrs:{"medium":"","color":"white"}},[_vm._v("far fa-times")])],1),_c('span',{staticClass:"title style"},[_vm._v(_vm._s(_vm.$t('ingredientPage.addIngredient')))])],1)],1),_c('v-card-text',[_c('v-form',{ref:"form",attrs:{"lazy-validation":"","disabled":_vm.fetchInProgress},model:{value:(_vm.isValid),callback:function ($$v) {_vm.isValid=$$v},expression:"isValid"}},[_c('v-row',{staticClass:"ma-0 pt-3",attrs:{"no-gutters":"","justify":"space-between"}},[_c('v-col',{attrs:{"xl":"7","lg":"7","md":"7","sm":"12","cols":"12"}},[_c('v-text-field',{attrs:{"outlined":"","label":_vm.$t('ingredientPage.newIngredients'),"rules":_vm.nameIngredientRules},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}})],1),_c('v-col',{attrs:{"xl":"4","lg":"4","md":"4","sm":"12","cols":"12"}},[_c('v-select',{attrs:{"label":_vm.$t('ingredientPage.unit'),"items":_vm.unitList,"item-text":"title","item-value":"id","outlined":"","rules":_vm.unitIngredientRules},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.selectedUnit.symbol))])]}},{key:"item",fn:function( ref){
var item = ref.item;
var on = ref.on;
return [_c('v-list-item',[_c('v-list-group',{attrs:{"value":false,"sub-group":""},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t(("ingredientPage." + (item.kind)))))])],1)]},proxy:true}],null,true)},_vm._l((item.unitList),function(unit,idx){return _c('v-list-item',_vm._g({key:idx,on:{"click":function($event){return _vm.onSelectedUnitChange(unit)}}},on),[_c('v-list-item-title',{staticClass:"unit"},[_vm._v(_vm._s(unit.symbol))])],1)}),1)],1)]}}]),model:{value:(_vm.selectedItem),callback:function ($$v) {_vm.selectedItem=$$v},expression:"selectedItem"}})],1)],1),_c('v-card',[_c('v-card-title',[_vm._v(_vm._s(_vm.$t('ingredientPage.specificPropertie')))]),_c('v-card-text',{staticClass:"pb-0"},[(_vm.specificPropertieList.length === 0)?_c('v-row',{staticClass:"ma-0",attrs:{"justify":"center"}},[_vm._v(_vm._s(_vm.$t('ingredientPage.noSpecificPropertie')))]):_vm._e(),_vm._l((_vm.specificPropertieList),function(i,idx){return _c('v-row',{key:idx,staticClass:"ma-0"},[_c('v-col',{staticClass:"py-0",attrs:{"xl":"7","lg":"7","md":"7","sm":"12","cols":"12"}},[_c('v-text-field',{attrs:{"outlined":"","label":_vm.$t('ingredientPage.addNewSpecificPropertie'),"rules":_vm.nameIngredientRules},model:{value:(i.name),callback:function ($$v) {_vm.$set(i, "name", $$v)},expression:"i.name"}})],1),_c('v-col',{staticClass:"py-0",attrs:{"xl":"4","lg":"4","md":"4","sm":"12","cols":"12"}},[_c('v-select',{attrs:{"label":_vm.$t('ingredientPage.unit'),"items":_vm.unitList,"item-text":"title","item-value":"id","outlined":"","rules":_vm.unitIngredientRules},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.getUnitSymbol(i.unitId)))])]}},{key:"item",fn:function( ref){
var item = ref.item;
var on = ref.on;
return [_c('v-list-item',[_c('v-list-group',{attrs:{"value":false,"sub-group":""},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t(("ingredientPage." + (item.kind)))))])],1)]},proxy:true}],null,true)},_vm._l((item.unitList),function(unit,idx){return _c('v-list-item',_vm._g({key:idx,on:{"click":function($event){return _vm.onSelectedPropertieUnitChange(unit, i)}}},on),[_c('v-list-item-title',{staticClass:"unit"},[_vm._v(_vm._s(unit.symbol))])],1)}),1)],1)]}}],null,true),model:{value:(_vm.selectedUnitSymbol),callback:function ($$v) {_vm.selectedUnitSymbol=$$v},expression:"selectedUnitSymbol"}})],1),_c('v-col',{attrs:{"xl":"1","lg":"1","md":"1","sm":"12","cols":"12","align":"center"}},[_c('v-btn',{attrs:{"fab":"","small":"","icon":"","color":"error","disabled":_vm.fetchInProgress},on:{"click":function($event){return _vm.deleteItem(i)}}},[_c('v-icon',{attrs:{"medium":""}},[_vm._v("far fa-times-circle")])],1)],1)],1)})],2),_c('v-card-actions',[_c('v-btn',{staticClass:"ml-2",attrs:{"color":"primary","disabled":_vm.fetchInProgress},on:{"click":_vm.createNewItem}},[_vm._v(" "+_vm._s(_vm.$t('ingredientPage.addNextPropertie')))])],1)],1)],1)],1),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{staticClass:"mr-4",attrs:{"disabled":_vm.fetchInProgress},on:{"click":_vm.onCloseDialog}},[_vm._v(_vm._s(_vm.$t('ingredientPage.closeButtonLabel')))]),_c('v-btn',{attrs:{"color":"primary","loading":_vm.fetchInProgress,"disabled":!_vm.isValid},on:{"click":_vm.onSaveClick}},[_vm._v(_vm._s(_vm.$t('ingredientPage.saveButtonLabel')))])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }