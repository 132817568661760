






















































































































































































import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'
import {SnackbarStateEnum} from "@/types/enums";
import IngredientProductDetail from "@/components/IngredientProductDetail.vue";
import Production from "@/model/Production";
import moment from "moment";
import SummaryStep from "@/components/SummaryStep.vue";
import {Action} from "vuex-class";
import {FETCH_PRODUCTION_BY_ID, UPDATE_PRODUCTION} from "@/store/action-types";
import { updateProductionPayload} from "@/types/payload";
import {AgGridVue} from "ag-grid-vue";
import { ProductionPackageResponse, ProductionStepResponse } from '@/types/response'
import UpdateProductionNumberDialog from "@/components/UpdateProductionNumberDialog.vue";
import TankList from "@/model/Tanklist";

@Component({
  components: {
    SummaryStep,
    IngredientProductDetail,
    AgGridVue,
    UpdateProductionNumberDialog
  }
})
export default class ProductionDetailDialog extends Vue {


  @Prop({type: Boolean, default: false}) readonly show!: boolean
  @Prop({type: String, default: false}) readonly productionId!: string
  @Prop({type: Array, required: true}) readonly tankList!: TankList[]
  @Action(UPDATE_PRODUCTION) updateProduction!: { (payload: updateProductionPayload): Promise<Production> }
  @Action(FETCH_PRODUCTION_BY_ID) fetchUpdatedData!: { (payload: string): Promise<Production> }

  private showSnackbar: boolean = false
  private snackBarMessage: string = ''
  private snackbarState: SnackbarStateEnum = SnackbarStateEnum.Success
  private menuExpiration: boolean = false
  private updatedExpirationDate: string = ''
  private minAllowedDate: string = ''
  private selectedProduction: Production | null = null
  private menuCreatedAt: boolean = false
  private updatedCreatedAtDate: string = ''
  private showUpdateProductionNumberDialog: boolean = false
  private loadingCreatedDate: boolean = false
  private loadingExpirationDate: boolean = false
  private loadingProductionNumber: boolean = false
  private loading: boolean = false

  get getAlcoholPercentage (): string {
    if(this.selectedProduction && this.selectedProduction.productionStep) {
      const foundLagerStep: ProductionStepResponse | undefined = this.selectedProduction.productionStep.find((item: ProductionStepResponse) => item.operation === 'lager')
      if (foundLagerStep && foundLagerStep.productionStepLager && foundLagerStep.productionStepLager.alcoholPercentage) {
        return foundLagerStep.productionStepLager.alcoholPercentage.toFixed(2)
      } else {
        return ''
      }
    } else {
      return ''
    }
  }

  getPackageDate (item: ProductionStepResponse) {
    if(item.productionStepPackage.createdAt) {
      return moment(item.productionStepPackage.createdAt,'YYYY-MM-DD').format('D.M.YYYY')
    } else {
      return ''
    }
  }

  getLagerDate (item: ProductionStepResponse) {
    if(item.productionStepLager.createdAt) {
      return moment(item.productionStepLager.createdAt,'YYYY-MM-DD').format('D.M.YYYY')
    } else {
      return ''
    }
  }

  getPrimaryDate (item: ProductionStepResponse) {
    if(item.productionStepPrimaryFermentation.createdAt) {
      return moment(item.productionStepPrimaryFermentation.createdAt,'YYYY-MM-DD').format('D.M.YYYY')
    } else {
      return ''
    }
  }


  getExpirationDate (item: Production) {
    if (item.expirationDate || this.updatedExpirationDate) {
      return moment(this.updatedExpirationDate ? this.updatedExpirationDate : item.expirationDate,'YYYY-MM-DD').format('D.M.YYYY')
    } else {
      return ''
    }
  }

  getProductionDate (item: Production) {
      return moment(item.createdAt,'YYYY-MM-DD').format('D.M.YYYY')
  }

  getTankNumber (item: string) {
    const foundItem: TankList | undefined = this.tankList.find((i: TankList) => i.id === item)
    return foundItem ? foundItem.label : ''
  }

  getSummaryVolume(item: ProductionPackageResponse[]): number {
    let volume: number = 0
    item.forEach((i: ProductionPackageResponse) => {
      if(i.amount > 0) {
        volume = volume + (i.volume * i.amount)
      }
    })
    return volume
  }

  get snackbarColor (): boolean {
    return this.snackbarState === SnackbarStateEnum.Success
  }

  getTotalVolume (item: any) {
    return (item.volume) * (item.amount)

  }

  onUpdateProductionNumberButtonClick ():void {
    this.showUpdateProductionNumberDialog = true
  }

  closeUpdateProductionNumberDialog (): void {
    this.showUpdateProductionNumberDialog = false
  }

  onCloseDetailDialog () {
    this.$emit('close')
  }

  onDeleteProduction () {
    this.$emit('delete')
  }

  onUpdateProduction (): void {
    this.$router.push({ path: `/production-stepper/${this.selectedProduction?.productId}`, query: {productionId: this.productionId}})
  }

  async fetchData (): Promise<void> {
    try {
      this.loading = true
      this.selectedProduction = await this.fetchUpdatedData(this.productionId)
      //this.$emit('updatedData')
      this.minAllowedDate = moment([this.selectedProduction.createdAt]).startOf('month').format('YYYY-MM-DD')
    } catch {
      this.snackBarMessage = this.$t('archivePage.errorData').toString()
      this.snackbarState = SnackbarStateEnum.Error
      this.showSnackbar = true
    } finally {
      this.loading = false
    }
  }

  async saveDateCreatedClick (): Promise<void> {
    const payload: updateProductionPayload = {
      productionId: this.productionId,
      expirationDate: null,
      createdAt: this.updatedCreatedAtDate,
      productionNumber: null
    }

    try {
      this.loadingCreatedDate = true
      await this.updateProduction(payload)
      this.menuCreatedAt = false
      this.fetchData()
      this.$emit('updatedData')
      this.snackBarMessage = this.$t('archivePage.dataEdit').toString()
      this.snackbarState = SnackbarStateEnum.Success
      this.showSnackbar = true
    } catch (e) {
      this.snackBarMessage = this.$t('archivePage.errorSaveProduct').toString()
      this.snackbarState = SnackbarStateEnum.Error
      this.showSnackbar = true
    } finally {
      this.loadingCreatedDate = false
    }
  }

  async saveDateExpirationClick (): Promise<void> {
    const payload: updateProductionPayload = {
      productionId: this.productionId,
      expirationDate: this.updatedExpirationDate,
      createdAt: null,
      productionNumber: null
    }

    try {
      this.loadingExpirationDate = true
      await this.updateProduction(payload)
      this.menuExpiration = false
      this.fetchData()
      this.$emit('updatedData')
      this.snackBarMessage = this.$t('archivePage.dataEdit').toString()
      this.snackbarState = SnackbarStateEnum.Success
      this.showSnackbar = true
    } catch (e) {
      this.snackBarMessage = this.$t('archivePage.errorSaveProduct').toString()
      this.snackbarState = SnackbarStateEnum.Error
      this.showSnackbar = true
    } finally {
      this.loadingExpirationDate = false
    }
  }

  async saveProductionNumberClick (productionNumber: string): Promise<void> {
    const payload: updateProductionPayload = {
      productionId: this.productionId,
      expirationDate: null,
      createdAt: null,
      productionNumber: productionNumber
    }

    try {
      this.loadingProductionNumber = true
      await this.updateProduction(payload)
      this.showUpdateProductionNumberDialog = false
      this.fetchData()
      this.$emit('updatedData')
      this.snackBarMessage = this.$t('archivePage.dataEdit').toString()
      this.snackbarState = SnackbarStateEnum.Success
      this.showSnackbar = true
    } catch (e) {
      this.snackBarMessage = this.$t('archivePage.errorSaveProduct').toString()
      this.snackbarState = SnackbarStateEnum.Error
      this.showSnackbar = true
    } finally {
      this.loadingProductionNumber = false
    }
  }

  mounted(): void {
    this.fetchData()
}}
