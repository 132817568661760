import { render, staticRenderFns } from "./SummaryStep.vue?vue&type=template&id=1cb13999&scoped=true&lang=pug&"
import script from "./SummaryStep.vue?vue&type=script&lang=ts&"
export * from "./SummaryStep.vue?vue&type=script&lang=ts&"
import style0 from "./SummaryStep.vue?vue&type=style&index=0&id=1cb13999&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1cb13999",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';
import { VTextarea } from 'vuetify/lib/components/VTextarea';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VCard,VCol,VContainer,VIcon,VList,VListItem,VListItemContent,VRow,VSimpleTable,VTextarea,VTooltip})
