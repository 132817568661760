













import Vue from 'vue'
import { Component } from 'vue-property-decorator'
import { AUTH_LOG_USER_OUT, SWITCH_APPLICATION_LANGUAGE, TOGGLE_APP_DRAWER } from '@/store/action-types'
import { Action, Getter } from 'vuex-class'

@Component
export default class AppToolbar extends Vue {
  @Action(TOGGLE_APP_DRAWER) toggleAppDrawer!: { (): void }
  @Action(AUTH_LOG_USER_OUT) logUserOut!: { (): void }

  onHamburgerClick (): void {
    this.toggleAppDrawer()
  }

  async onLogoutButtonClick (): Promise<void> {
    try {
      await this.logUserOut()
      this.$router.push({ name: 'Login' })
    } catch (e) {

    }
  }
}
