












































































import Vue from 'vue'
import _ from 'lodash'
import {Component, Prop} from 'vue-property-decorator'
import {SnackbarStateEnum} from "@/types/enums";
import {Action} from "vuex-class";
import {CREATE_INGREDIENT, FETCH_UNIT_LIST} from "@/store/action-types";
import Unit from "@/model/Unit";
import {NewIngredientPayload} from "@/types/payload";
import i18n from "@/plugins/i18n";
import {UnitListItem} from "@/types/types";
import SpecificPropertieList from "@/model/SpecificPropertieList";

@Component
export default class AddNewIngredientDialog extends Vue {

  $refs!: {
    form: Vue & {
      validate(): boolean
    }
  }

  @Prop({ type: Boolean, default: false }) readonly show!: boolean
  @Action(FETCH_UNIT_LIST) fetchUnitList!: { (): Promise<Unit[]> }
  @Action(CREATE_INGREDIENT) saveNewIngredient!: { (payload: NewIngredientPayload): Promise<void> }

  private snackbarState: SnackbarStateEnum = SnackbarStateEnum.Success
  private snackBarMessage: string = ''
  private showSnackbar: boolean = false
  private fetchInProgress: boolean = false
  private isValid: boolean = false
  private selectedItem: UnitListItem | null = null
  private selectedUnitSymbol: UnitListItem | null = null
  private specificPropertieList: SpecificPropertieList[] = []
  private unitList: UnitListItem[]  = []
  private selectedUnit: Unit | null = null
  private nameIngredientRules: Array<(v: string) => string | boolean> = [
    (v: string) => !!v || i18n.t('loginPage.valueIsRequiredMessage').toString(),
  ]
  private unitIngredientRules: Array<(v: string) => string | boolean> = [
    (v: string) => !!v || i18n.t('loginPage.valueIsRequiredMessage').toString(),
  ]
  private name: string | null = null

  get snackbarColor (): boolean {
    return this.snackbarState === SnackbarStateEnum.Success
  }

  getUnitSymbol (unitId: string): string {
    if(this.unitList) {
      let foundSymbol: string = ''
      this.unitList.forEach((item: UnitListItem) => {
        const foundUnit: Unit | undefined = item.unitList.find((item: Unit) => item.id === unitId)
        if(foundUnit){
          foundSymbol = foundUnit.symbol
        }
      })
      return foundSymbol
    } else {
      return ''
    }
  }

  onSelectedUnitChange (unit: Unit): void {
    this.selectedUnit = unit
  }

  onSelectedPropertieUnitChange (unit: Unit, item: any): void {
    item.unitId = unit.id
  }

  createNewItem (): void {
    if (this.specificPropertieList.length > 0) {
      let maxOrder: SpecificPropertieList | undefined = _.maxBy(this.specificPropertieList, 'order')
      if (maxOrder)
        this.specificPropertieList.push({
          id: "",
          name: "",
          unitId: "",
          order: maxOrder.order + 1,
          unit: undefined
        })
    } else {
      this.specificPropertieList.push({
        id: "",
        name: "",
        unitId: "",
        order: 1,
        unit: undefined
      })
    }
  }

  deleteItem (i: SpecificPropertieList): void {
    const index = this.specificPropertieList.indexOf(i)
    this.specificPropertieList.splice(index, 1)
  }

  onCloseDialog (): void {
    this.$emit('close')
  }

   async onSaveClick (): Promise<void> {
     if (this.$refs.form.validate()) {
       this.fetchInProgress = true
       let payload: NewIngredientPayload = {
         name: this.name!,
         unitId: this.selectedUnit!.id,
         specificPropertieList: this.specificPropertieList
       }

       try {
         await this.saveNewIngredient(payload)
         this.$emit('saved')
       } catch (e) {
         this.snackBarMessage = this.$t(`ingredientPage.${e.response.data.code}`).toString()
         this.snackbarState = SnackbarStateEnum.Error
         this.showSnackbar = true
       } finally {
         this.fetchInProgress = false
       }
     }
  }

  async fetchUnit (): Promise<void> {
    try {
      const response: Unit[] = await this.fetchUnitList()
      //@ts-ignore
      this.unitList = _.chain(response)
          .groupBy("kind")
          .map((value: Unit, key: string) => ({ kind: key, unitList: value }))
          .value()
    } catch (e) {
      this.snackBarMessage = this.$t('ingredientPage.errorData').toString()
      this.snackbarState = SnackbarStateEnum.Error
      this.showSnackbar = true
    }
  }

  mounted (): void {
    this.fetchUnit()
  }
}
