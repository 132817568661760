import { RecipeResponse} from "@/types/response";
import RecipeIngredient from "@/model/RecipeIngredient";

export default class Recipe {
  private constructor (
      readonly createdAt: string,
      readonly id: string,
      readonly productId: string,
      readonly version: number,
      readonly recipeIngredient: RecipeIngredient[]
  ) {}

  public static createFromResponseList (response: RecipeResponse): Recipe {
      return new Recipe(
          response.createdAt,
          response.id,
          response.productId,
          response.version,
          RecipeIngredient.createFromResponseList(response.recipeIngredient)
      )
  }
}
