//<editor-fold desc="Global mutation">
export const TOGGLE_APP_DRAWER_MUTATION = 'TOGGLE_APP_DRAWER_MUTATION'
export const SET_DRAWER_STATE_MUTATION = 'SET_DRAWER_STATE_MUTATION'
export const APP_UPDATE_IS_AVAILABLE = 'APP_UPDATE_IS_AVAILABLE'
export const SET_CURRENT_UI_LANGUAGE = 'SET_CURRENT_UI_LANGUAGE'
//</editor-fold>

//<editor-fold desc="Auth module mutations">
export const AUTH_SET_CURRENT_USER = 'AUTH_SET_CURRENT_USER'
export const AUTH_SET_TOKEN = 'AUTH_SET_TOKEN'
//</editor-fold>
