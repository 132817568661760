





















import Vue from 'vue'
import { Component } from 'vue-property-decorator'
import ProductionStepper from "@/components/ProductionStepper.vue";
import {Action} from "vuex-class";
import { FETCH_PRODUCTS_LIST_WITH_IMAGES } from '@/store/action-types'
import Product from "@/model/Product";

@Component({
  components: {
    ProductionStepper
  }
})
export default class ProductionPage extends Vue {
  @Action(FETCH_PRODUCTS_LIST_WITH_IMAGES) fetchProductListWithImages!: { (): Promise<Product[]> }

  private fetchInProgress: boolean = false
  private productList: Product[] = []
  private selectedProduct: Product | null = null

  getImageSrc (item: Product): String {
    if(item.productImage) {
      return `data:${item.productImage.imageType};base64,${item.productImage.image}`
    } else {
      return ''
    }
  }

  onProductClick (item: Product): void {
    this.selectedProduct = item
    this.$router.push({ path: `/production-stepper/${item.id}`, query: {productionId: ''} })
  }

  async fetchProductsList (): Promise<void> {
    try {
      this.fetchInProgress = true
      this.productList = await this.fetchProductListWithImages()
    } catch (e) {

    } finally {
      this.fetchInProgress = false
    }
  }

  mounted (): void {
    this.fetchProductsList()
  }
}
