
















import Vue from 'vue'
import {Component, Prop} from 'vue-property-decorator'

@Component
export default class WarningDialog extends Vue {

  @Prop({ type: Boolean, default: false }) readonly show!: boolean
  @Prop({ type: String }) readonly warningMessage!: string
  @Prop({ type: String }) readonly warningProductionMessage!: string

  onCloseDialog() {
    this.$emit('close')
  }

  onNoButtonClick() {
    this.onCloseDialog()
  }

  onYesButtonClick() {
    this.$emit('yes')
  }
}
