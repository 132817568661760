import { ProductsResponse, RecipeResponse } from '@/types/response'
import Recipe from "@/model/Recipe";
import ProductConfiguration from "@/model/ProductConfiguration";
import _ from 'lodash'
import ProductImage from '@/model/ProductImage'

export default class Product {
  private constructor (
    readonly id: string,
    readonly name: string,
    readonly isArchived: boolean,
    readonly recipe: Recipe | null,
    readonly productImage: ProductImage | null,
    readonly productConfiguration: ProductConfiguration[] | null
  ) {}

  public static createFromResponseList (response: ProductsResponse[]): Product[] {
      return response.map((item: ProductsResponse) => {
          return new Product(
              item.id,
              item.name,
              item.isArchived,
              null,
              item.productImage ? ProductImage.createFromResponse(item.productImage) : null,
              null
          )
      })
  }

    public static createFromResponse (response: ProductsResponse, recipeId?: string): Product {
        let foundRecipe: RecipeResponse | undefined = undefined

        if(recipeId && response.recipe) {
          foundRecipe = response.recipe.find((item: RecipeResponse) => item.id === recipeId)
        } else if (response.recipe) {
          foundRecipe = _.maxBy(response.recipe, 'version')
        }

        return new Product(
            response.id,
            response.name,
            response.isArchived,
            foundRecipe ?  Recipe.createFromResponseList(foundRecipe) : null,
            response.productImage ? ProductImage.createFromResponse(response.productImage) : null,
            response.productConfiguration ? ProductConfiguration.createFromResponseList(response.productConfiguration) : null
        )
    }

    public static createEmpty (): Product {
        return new Product(
            '',
            '',
            false,
            null,
            null,
            null
        )
    }
}
