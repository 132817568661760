














































































































import Vue from 'vue'
import { Component } from 'vue-property-decorator'
import {Action} from "vuex-class";
import {DELETE_PRODUCTION_BY_ID, FETCH_ARCHIVE_LIST, FETCH_TANK_LIST} from "@/store/action-types";
import { SnackbarStateEnum } from "@/types/enums";
import moment from "moment";
import Production from "@/model/Production";
import {AgGridVue} from "ag-grid-vue";
import {ColDef, GridApi, GridOptions, ColumnApi, ValueFormatterParams, ValueGetterParams} from "ag-grid-community";
import ProductionStatusCellRenderer from "@/components/ProductionStatusCellRenderer.vue";
import ProductionDetailDialog from "@/components/ProductionDetailDialog.vue";
import { DeleteProductionPayload, PeriodFilterPayload } from '@/types/payload'
import DeleteProductionDialog from "@/components/DeleteProductionDialog.vue";
import ProductionExpirationDateCellRenderer from "@/components/ProductionExpirationDateCellRenderer.vue";
import ProductionCommentCellRenderer from "@/components/ProductionCommentCellRenderer.vue";
import TankList from "@/model/Tanklist";

@Component({
  components: {
    AgGridVue,
    ProductionDetailDialog,
    DeleteProductionDialog
  }
})

export default class ArchivePage extends Vue {
  $refs!: {
    menuFrom: Vue & {
      save(date: string): void,
    },
    menuTo: Vue & {
      save(date: string): void,
    },
  }

  @Action(FETCH_ARCHIVE_LIST) fetchArchiveList!: { (payload?: PeriodFilterPayload): Promise<Production[]> }
  @Action(DELETE_PRODUCTION_BY_ID) deleteProduction!: { (payload: DeleteProductionPayload): Promise<void> }
  @Action(FETCH_TANK_LIST) fetchTankList!: { (): Promise<TankList[]> }

  private gridOptions: GridOptions = {}
  private columnDefs: ColDef[] = []
  private rowData: Production[] | null = null
  private gridFrameworkComponents: Object = {
    productionStatusCellRenderer: ProductionStatusCellRenderer,
    productionExpirationDateCellRenderer: ProductionExpirationDateCellRenderer,
    productionCommentCellRenderer: ProductionCommentCellRenderer}
  private gridApi: GridApi | null | undefined
  private columnApi: ColumnApi | null | undefined
  private selectedProduction: Production | null = null
  private snackbarState: SnackbarStateEnum = SnackbarStateEnum.Success
  private snackBarMessage: string = ''
  private showSnackbar: boolean = false
  private archiveList: Production[] = []
  private dateFrom: string = ''
  private dateTo: string = ''
  private menuFrom: boolean = false
  private menuTo: boolean = false
  private formattedDateFrom: string = ''
  private formattedDateTo: string = ''
  private minAllowedDate: string = moment([2020, 8]).startOf('month').format('YYYY-MM-DD')
  private maxAllowedDate: string = moment().add(1, 'month').endOf('month').format('YYYY-MM-DD')
  private showProductionDetailDialog: boolean = false
  private productId: string = ''
  private showWarningDialog: boolean = false
  private warningProductionMessage: string =  `${this.$t('archivePage.warningMessage')}`
  private tankList: TankList[] = []
  private isFilterLoading: boolean = false

  onResize (): void {
    if(this.gridApi) {
      this.gridApi.sizeColumnsToFit()
    }
  }

  createColDefs(): ColDef[] {
    let hideDetails = false

    if (
        screen.orientation.type.includes('portrait')
        && ['xs', 'sm'].includes(this.$vuetify.breakpoint.name as string)
    ) {
      hideDetails = true
    }

    return [
      {
        minWidth: 120,
        maxWidth: 120,
        cellStyle: {display: 'flex', 'justify-content': 'center'},
        headerName: this.$t('archivePage.productionStatusLabel').toString(),
        suppressMenu: hideDetails,
        field: 'completed',
        filter: false,
        cellRenderer: "productionStatusCellRenderer",
      },
      {
        minWidth: 200,
        headerName: this.$t('archivePage.productionNameLabel').toString(),
        field: 'product.name',
      },
      {
        cellStyle: {display: 'flex', 'justify-content': 'end'},
        minWidth: 130,
        headerName: this.$t('archivePage.productionNumberLabel').toString(),
        field: 'productionNumber',
      },
      {
        minWidth: 200,
        headerName: this.$t('archivePage.productionTotalProductionLabel').toString(),
        cellStyle: {display: 'flex', 'justify-content': 'end'},
        field: 'tankVolume',
        valueGetter(params: ValueGetterParams): string {
         let foundData: any = params.data.productionStep.find((item: any) => item.operation === 'package')
         if(foundData && foundData.productionStepPackage &&  foundData.productionStepPackage.volume) {
           return foundData.productionStepPackage.volume.toFixed(2)
         } else {
           return '-'
         }
        },
      },
      {
        minWidth: 170,
        headerName: this.$t('archivePage.productionAlcoholValueLabel').toString() ,
        cellStyle: {display: 'flex', 'justify-content': 'end'},
        field:'alcoholPercentage',
        valueGetter(params: ValueGetterParams): string {
          let foundData: any = params.data.productionStep.find((item: any) => item.operation === 'lager')
          if(foundData && foundData.productionStepLager &&  foundData.productionStepLager.alcoholPercentage) {
            return foundData.productionStepLager.alcoholPercentage.toFixed(2)
          } else {
            return '-'
          }
        },
      },
      {
        minWidth: 150,
        headerName: this.$t('archivePage.productionDateLabel').toString(),
        field: 'createdAt',
        cellStyle: {display: 'flex', 'justify-content': 'center'},
        valueFormatter(params: ValueFormatterParams): string {
          return params.data.createdAt ? moment(params.data.createdAt).format('D.M.YYYY') : ''
        }
      }
    ]
  }

  onGridReady(params: GridOptions) {
    this.gridApi = params.api
    this.columnApi = params.columnApi
  }

  beforeMount() {
    this.gridOptions = {
      rowHeight: 40,
      rowSelection: 'multiple',
      rowDeselection: true,
      rowMultiSelectWithClick: false,
      suppressContextMenu: true,

      defaultColDef: {
        sortable: true,

        filter: 'agTextColumnFilter',

        filterParams: {
          debounceMs: 250,
        },

        floatingFilter: true,
        floatingFilterComponentParams: {
          suppressFilterButton: true
        }
      },

      overlayLoadingTemplate:
          `<div class="ag-overlay-loading-center" style="font-size: 15px">
         <i class="fas fa-spinner fa-pulse"></i>
         <span style="padding-left: 10px">${ this.$t('archivePage.loadingText') } </span>
     </div>`,

      onFirstDataRendered (params: any) {
        params.api.sizeColumnsToFit()
      },
    }

    this.columnDefs = this.createColDefs()
  }


  get snackbarColor (): boolean {
    return this.snackbarState === SnackbarStateEnum.Success
  }

  getFormattedDateFrom(date?: string): string {
    if (date) {
      return this.formattedDateFrom = moment(date).format('D.M.YYYY')
    } else {
      return this.formattedDateFrom = moment(this.dateFrom).format('D.M.YYYY')
    }
  }

  getFormattedDateTo(date?: string): string {
    if (date) {
      return this.formattedDateTo = moment(date).format('D.M.YYYY')
    } else {
      return this.formattedDateTo = moment(this.dateTo).format('D.M.YYYY')
    }
  }

  saveFrom(date: string): void {
    this.$refs.menuFrom.save(date)
    this.getFormattedDateFrom(date)
  }

  saveTo(date: string): void {
    this.$refs.menuTo.save(date)
    this.getFormattedDateTo(date)
  }

  onDatesFilterConfirmClick () {
    if (this.dateFrom && this.dateTo) {
      if (Date.parse(this.dateFrom) < Date.parse(this.dateTo)) {
        let payload: PeriodFilterPayload = {
          periodStart: this.dateFrom,
          periodEnd: this.dateTo
        }
        this.onFetchArchiveList(payload)
      } else {
        this.showSnackbar = true
        this.snackBarMessage = this.$t('archivePage.dateFilterError').toString()
        this.snackbarState = SnackbarStateEnum.Error
      }
    } else if (this.dateFrom || this.dateTo) {
      let payload: PeriodFilterPayload = {
        periodStart: this.dateFrom ? this.dateFrom : null,
        periodEnd: this.dateTo ? this.dateTo : null
      }
      this.onFetchArchiveList(payload)
    } else {
      this.onFetchArchiveList()
    }
  }

  onDetailRowClick (item: any): void {
    if(item.data.completed === true) {
      this.selectedProduction = item.data
      this.showProductionDetailDialog = true
    } else {
      this.productId = item.data.productId
      this.$router.push({ path: `/production-stepper/${item.data.productId}`, query: {productionId: item.data.id}})
    }

  }

  closeDetailDialog () {
    this.showProductionDetailDialog = false
  }

  onCloseWarningDialog (): void {
    this.showWarningDialog = false
  }

  updatedData () {
    this.onFetchArchiveList()
  }

  async fetchTankData() :Promise<void> {
    this.tankList = await this.fetchTankList()
  }

   async onDeleteProduction (deleteMessage: string) {
     this.showWarningDialog = false
    if (this.selectedProduction && this.selectedProduction.id) {
      let payload: DeleteProductionPayload = {
        productionId: this.selectedProduction.id,
        deleteMessage: deleteMessage
      }

      try {
        await this.deleteProduction(payload)
        this.showProductionDetailDialog = false
        this.snackBarMessage = this.$t('archivePage.deletedData').toString()
        this.snackbarState = SnackbarStateEnum.Success
        this.showSnackbar = true
        this.onFetchArchiveList()
      } catch (e) {
        this.snackBarMessage = this.$t('archivePage.errorDeletedData').toString()
        this.snackbarState = SnackbarStateEnum.Error
        this.showSnackbar = true
      }
    }
  }

  async onFetchArchiveList (payload?: PeriodFilterPayload): Promise<void> {
    try {
      this.isFilterLoading = true
      this.archiveList = await this.fetchArchiveList(payload)
      this.rowData = this.archiveList
    } catch (e) {
      this.snackBarMessage = this.$t('archivePage.errorData').toString()
      this.snackbarState = SnackbarStateEnum.Error
      this.showSnackbar = true
    } finally {
      this.isFilterLoading = false
    }
  }

  mounted (): void {
    this.onFetchArchiveList()
    this.fetchTankData()
  }
}
