import {RecipeIngredientResponse} from "@/types/response";
import Unit from '@/model/Unit'
import RecipeIngredientSpecificPropertie from '@/model/RecipeIngredientSpecificPropertie'

export default class RecipeIngredient {
  private constructor (
    readonly id: string,
    readonly name: string,
    readonly unitId: string,
    readonly recipeId: string,
    readonly amount: number,
    readonly order: number,
    readonly createdAt: string,
    readonly unit: Unit,
    readonly recipeIngredientSpecificPropertieList: RecipeIngredientSpecificPropertie[]
  ) {}

  public static createFromResponseList (response: RecipeIngredientResponse[]): RecipeIngredient[] {
      return response.map((item: RecipeIngredientResponse) => {
          return new RecipeIngredient(
              item.id,
              item.name,
              item.unitId,
              item.recipeId,
              item.amount,
              item.order,
              item.createdAt,
              Unit.createFromResponse(item.unit),
              RecipeIngredientSpecificPropertie.createFromResponseList(item.recipeIngredientSpecificPropertieList)
          )
      })
  }

}
