
























import Vue from 'vue'
import {Component, Prop} from 'vue-property-decorator'
import i18n from "@/plugins/i18n";
import { PackagePayload, TankPayload } from '@/types/payload'
import TankList from '@/model/Tanklist'
import TankType from '@/model/TankType'

@Component
export default class TankDialog extends Vue {

  $refs!: {
    form: Vue & {
      validate(): boolean
    }
  }

  @Prop({ type: Boolean, default: false }) readonly show!: boolean
  @Prop({ type: Object, default: false }) readonly selectedTank!: TankList | null
  @Prop({ type: Array, default: false }) readonly tankTypeList!: TankType[] | null
  @Prop({ type: Boolean, default: false }) readonly createInProgress!: boolean
  @Prop({ type: Boolean, default: false }) readonly updateInProgress!: boolean
  @Prop({ type: Boolean, default: false }) readonly deleteInProgress!: boolean

  private isValid: boolean = false
  private name: string | null = null
  private selectedTypeId: string | null = null
  private nameRules: Array<(v: string) => string | boolean> = [
    (v: string) => !!v || i18n.t('valueIsRequiredMessage').toString(),
  ]
  private tanktypeRules: Array<(v: string) => string | boolean> = [
    (v: string) => !!v || i18n.t('valueIsRequiredMessage').toString(),
  ]

  get getTitle (): string {
    if(this.selectedTank) {
      return this.$t('settingsPage.updateTankTitle').toString()
    } else {
      return this.$t('settingsPage.addTankTitle').toString()
    }
  }

  onCloseDialog (): void {
    if(!this.createInProgress && !this.updateInProgress && !this.deleteInProgress) {
      this.$emit('close')
    }
  }

   onSaveClick (): void {
    if(this.$refs.form.validate() && this.name && this.selectedTypeId) {
      const payload: TankPayload = {
        id: this.selectedTank ? this.selectedTank.id : '',
        label: this.name,
        typeId: this.selectedTypeId
      }
      if(this.selectedTank) {
        this.$emit('update',  payload)
      } else {
        this.$emit('create',  payload)
      }
    }
  }

  onDeleteClick (): void {
    if(this.selectedTank) {
      this.$emit('delete',  this.selectedTank.id)
    }
  }

  mounted (): void {
    if(this.selectedTank) {
      this.name = this.selectedTank.label
      this.selectedTypeId = this.selectedTank.type.id
    }
  }
}
