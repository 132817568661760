










































































import Vue from 'vue'
import {Component, Prop, Watch} from 'vue-property-decorator'
import moment from "moment";
import i18n from "@/plugins/i18n";
import {ProductionStepLagerPayload} from "@/types/payload";
import {ProductionStepResponse} from "@/types/response";
import {Action} from "vuex-class";
import {FETCH_PRODUCTION_STEP_BY_ID, FETCH_TANK_LIST} from "@/store/action-types";
import {StepList} from "@/types/types";
import TankList from "@/model/Tanklist";


@Component({
  components: {
  }
})
export default class LagerStep extends Vue {

  $refs!: {
    menuFrom: Vue & {
      save(date: string): void,
    },
    form: Vue & {
      validate(): boolean,
      resetValidation(): boolean
    }
  }

  @Prop({type: Object, required: false}) value!: ProductionStepLagerPayload
  @Prop({type: Object, required: true}) selectedStep!: StepList
  @Prop({type: Boolean, default: false}) saveIsProgress!: boolean
  @Action(FETCH_PRODUCTION_STEP_BY_ID) fetchProductionStepById!: { (payload: string): Promise<ProductionStepResponse> }
  @Action(FETCH_TANK_LIST) fetchTankList!: { (): Promise<TankList[]> }

  private createdAt: string = ''
  private menuFrom: boolean = false
  private tankNumberRules: Array<(v: string) => string | boolean> = [
    (v: string) => !!v || i18n.t('loginPage.valueIsRequiredMessage').toString(),
  ]
  private tankVolumeRules: Array<(v: string) => string | boolean> = [
    (v: string) => {
      const pattern = /^(?!0*[.,]0*$|[.,]0*$|0*$)\d+[,.]?\d{0,2}$/
      return pattern.test(v) || i18n.t('loginPage.valueIsRequiredMessage').toString()
    }
  ]
  private dateRules: Array<(v: string) => string | boolean> = [
    (v: string) => !!v || i18n.t('loginPage.valueIsRequiredMessage').toString(),
  ]
  private alcoholPercentageRules: Array<(v: string) => string | boolean> = [
    (v: string) => {
      const pattern = /^(\d+(\.\d+)?)$/
      return pattern.test(v) || i18n.t('loginPage.valueIsRequiredMessage').toString()
    }
  ]
  private residualSugarRules: Array<(v: string) => string | boolean> = [
    (v: string) => {
      const pattern = /^(\d+(\.\d+)?)$/
      return pattern.test(v) || i18n.t('loginPage.valueIsRequiredMessage').toString()
    }
  ]
  private isEdit: boolean = false
  private tankList: TankList[] = []
  private loading: boolean = false

  @Watch('selectedStep')
  stepItem(): void {
    if(this.selectedStep.productionStepId) {
      this.isEdit = true
      this.fetchProductionStepData()
    } else {
      this.isEdit = false
    }
  }

  get lagerData(): ProductionStepLagerPayload {
    return this.value
  }

  set lagerData(val: ProductionStepLagerPayload) {
    this.$emit('input', val)
  }

  get getFormattedDateFrom(): string {
    return moment(this.lagerData.createdAt, 'YYYY-MM-DD').format('D.M.YYYY')
  }

  async fetchTankData():Promise<void> {
    this.tankList = await this.fetchTankList()
    this.tankList = this.tankList.filter((item: TankList) => item.type.label === 'Ležiacký')
  }

  async fetchProductionStepData (): Promise<void> {
    const payload: string = this.selectedStep.productionStepId!
    try {
      this.loading = true
      const response: ProductionStepResponse = await this.fetchProductionStepById(payload)
      const stepData: ProductionStepLagerPayload = {
        tankId: response.productionStepLager.tankId,
        tankVolume: response.productionStepLager.tankVolume.toFixed(2),
        alcoholPercentage: response.productionStepLager.alcoholPercentage.toFixed(2),
        residualSugar: response.productionStepLager.residualSugar.toFixed(2),
        createdAt: response.productionStepLager.createdAt
      }
      this.lagerData = stepData
    } catch (e) {
    } finally {
      this.loading = false
    }
  }

  mounted (): void {
    this.fetchTankData()
    if(this.selectedStep.productionStepId) {
      this.isEdit = true
      this.fetchProductionStepData()
    } else {
      this.isEdit = false
    }
  }

  public dataValidation(): boolean {
    return this.$refs.form.validate()
  }

}
