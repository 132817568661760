




















































import Vue from 'vue'
import {Component, Prop} from 'vue-property-decorator'
import {SnackbarStateEnum} from "@/types/enums";
import {EditedIngredient, ProductionStepIngredientSpecificPropertieList} from "@/types/types";
import RecipeIngredient from "@/model/RecipeIngredient";
import RecipeIngredientSpecificPropertie from '@/model/RecipeIngredientSpecificPropertie'
import i18n from '@/plugins/i18n'

@Component
export default class ProductionIngredientDialog extends Vue {

  $refs!: {
    form: Vue & {
      validate(): boolean
    }
  }

  @Prop({ type: Boolean, default: false }) readonly show!: boolean
  @Prop({type: Object, required: true}) selectedIngredient!: RecipeIngredient
  @Prop({type: Object}) editedIngredientData!: EditedIngredient

  private amount: number = 0
  private snackbarState: SnackbarStateEnum = SnackbarStateEnum.Success
  private comment: string = ''
  private specificPropertieList: ProductionStepIngredientSpecificPropertieList[] = []
  private amountRules: Array<(v: string) => string | boolean> = [
    (v: string) => {
      if((!!v || parseInt(v) === 0) && this.selectedIngredient.amount + parseFloat(parseFloat(v).toFixed(2)) >= 0) {
        return true
      } else {
        return i18n.t('loginPage.badIngredientEditValue').toString()
      }
    },
  ]

  get snackbarColor (): boolean {
    return this.snackbarState === SnackbarStateEnum.Success
  }

  onAmountChange (data: string) {
    this.amount = parseFloat(data)
  }

  onSpecificPropertieChange (item: ProductionStepIngredientSpecificPropertieList): void {
    item.amount = item.amount != null &&  (item.amount >= 0 || item.amount <= 0) ? parseFloat(item.amount.toString()) : null
  }

  onCloseProductionIngredientDialog (): void {
    this.$emit('close')
  }

  onMinusAmountClick (value: number) {
    this.amount = this.amount - value
  }

  onPlusAmountClick (value: number) {
    this.amount = this.amount + value
  }

  saveEditedIngredient (): void {
    if(this.$refs.form.validate()) {
      const payload: EditedIngredient = {
        ingredientId: this.selectedIngredient.id,
        amount: this.amount,
        comment: this.comment,
        productionStepIngredientSpecificPropertieList: this.specificPropertieList
      }
      this.$emit('ingredientEdit', payload)
    }
  }

  mounted (): void {
    this.specificPropertieList = this.selectedIngredient.recipeIngredientSpecificPropertieList.map((item: RecipeIngredientSpecificPropertie) => {
      return {
        id: item.id!,
        recipeIngredientSpecificPropertieId: item.id!,
        name: item.name,
        unitId: item.unitId,
        order: item.order,
        amount: 0.00,
        unit: item.unit!
      }
    })

    if(this.editedIngredientData) {
      this.amount = this.editedIngredientData.amount
      this.comment = this.editedIngredientData.comment
      this.specificPropertieList = this.editedIngredientData.productionStepIngredientSpecificPropertieList.map((item: ProductionStepIngredientSpecificPropertieList) => {
        return {
          id: item.id,
          recipeIngredientSpecificPropertieId: item.recipeIngredientSpecificPropertieId,
          name: item.name,
          unitId: item.unitId,
          order: item.order,
          amount: item.amount != null &&  (item.amount >= 0 || item.amount <= 0) ? item.amount : null,
          unit: item.unit
        }
      })
    }
  }
}
