import {SpecificPropertieListResponse} from "@/types/response";
import Unit from "@/model/Unit";

export default class SpecificPropertieList {
  private constructor (
        readonly id: string | null,
        readonly name: string,
        readonly unitId: string,
        readonly order: number,
        readonly unit: Unit | undefined
  ) {}

  public static createFromResponseList (response: SpecificPropertieListResponse[]): SpecificPropertieList[] {
      return response.map((item: SpecificPropertieListResponse) => {
          return new SpecificPropertieList(
              item.id,
              item.name,
              item.unitId,
              item.order,
              Unit.createFromResponse(item.unit)
          )
      })
  }

    public static createFromResponse (response: SpecificPropertieListResponse): SpecificPropertieList {
        return new SpecificPropertieList(
            response.id,
            response.name,
            response.unitId,
            response.order,
            Unit.createFromResponse(response.unit)
        )
    }
}
