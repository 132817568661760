


















import Vue from 'vue'
import {Component, Prop} from 'vue-property-decorator'
import i18n from '@/plugins/i18n'

@Component
export default class DeleteProductionDialog extends Vue {

  $refs!: {
    form: Vue & {
      validate(): boolean,
      resetValidation(): boolean
    }
  }

  @Prop({ type: Boolean, default: false }) readonly show!: boolean
  @Prop({ type: String }) readonly warningMessage!: string
  @Prop({ type: String }) readonly warningProductionMessage!: string

  private deleteMessage: string = ''
  private rules: any = [
    (value: any) => !!value || i18n.t('loginPage.valueIsRequiredMessage').toString()
  ]

  onCloseDialog() {
    this.$emit('close')
  }

  onNoButtonClick() {
    this.onCloseDialog()
  }

  onYesButtonClick() {
    if(this.$refs.form.validate()) {
      this.$emit('yes', this.deleteMessage)
    }
  }
}
